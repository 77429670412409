import React, {useEffect, useState} from 'react';
import './CustomLatency.css';
import {Box, Flex, Text} from "@chakra-ui/react";
import {Latency} from "../../../icons";

interface Props {
    title: string;
    latency: string;
}

const CustomLatency: React.FC<Props> = ({title,latency}) => {
    const latencyValue: number= Number(latency);
    const latencyText: string = `${latency} s`;
    const [customColor, setCustomColor]= useState('');

    useEffect(() => {
        if (latencyValue <= 1) {
            setCustomColor('#2dac93');
        } else if (latencyValue <= 2) {
            setCustomColor('#ffa900');
        } else if (latencyValue >= 3) {
            setCustomColor('#db071e');
        }
    }, [latencyValue]);

    return (
        <Box className="wrapper-custom-latency">
            <Text className={'latency-title'} textAlign={'left'}>{title}</Text>
            <Flex direction="row" className={'latency-container'} marginTop={'1rem'}  alignItems="center" >
                <Latency color={customColor}/>
                <Text className={'latency-value'} marginInlineStart={'.5rem'}>{latencyText}</Text>
            </Flex>
        </Box>
    );
};

export default CustomLatency;