import { Button } from "@chakra-ui/react";
import React from "react";

interface ActionButtonProps {
  text: string;
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({ text, onClick, isLoading, isDisabled }) => {
    return (
        <Button 
            colorScheme="blue" 
            bg="blue.700"
            onClick={onClick} 
            _hover={{ bg: "blue.600" }}
            isLoading={isLoading}
            isDisabled={isDisabled}
        >
            {text}
        </Button>
    );
};

export default ActionButton;
