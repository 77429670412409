import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import MainTitle from "../../../../ui/Components/MainTitle";
import Subtitle from "../../../../ui/Components/SubtitleSeccion"; 
import SelectInput from "../../../../ui/Components/SelectInput"; 
import TextAreaInput from "../../../../ui/Components/TextAreaInput"; 
import ActionButton from "../../../../ui/Components/Button";
import { memo, useState } from "react";
import { useForm, Controller, useWatch  } from "react-hook-form";
import GenerativeAILogo from '../../../../icons/brain/generativeai';
import useToastError from '../../../../hooks/useToastError';
import ChatBox from '../../../../ui/Components/ChatBox';
import { providersListGenerative } from './mocks';
import './stylesGenerativeAi.css'
import { useNavigate } from "react-router-dom";
import { FormValues } from './interfaces';
import { useTranslation } from "react-i18next";

const GenerativeAI = memo(() => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { handleSubmit, control, setValue, formState: { isSubmitting } } = useForm<FormValues>();
    const showToastError = useToastError();
    const [chatEnable, setChatEnable] = useState(false);
    const [sendUserMessage, setSendUserMessage] = useState<FormValues>({
        model: "",
        system_prompt: "",
    });

    const selectedProviders = useWatch({
        control,
        name: "model",
        defaultValue: "",
    });

    const onSubmit = async (data: FormValues) => {
        const sendData = {
            model: data.model,
            system_prompt: data.system_prompt,
        };
        if(sendData){
            setChatEnable(true);
            setSendUserMessage(sendData)
            // reset select
            setValue("model","");
        }
    };

    const backNavigate = () => {
        if (chatEnable){
            setChatEnable(false)
        } else {
            navigate('/')

        }
    }
    
    return (
        <Flex flexDir="column">
            <Text 
                fontFamily="Lato-regular"  
                fontSize="18px" 
                as="a"
                color="white.50" 
                onClick={backNavigate}
                className="back-button"
            >
                {t("< back")}
            </Text>
            <Box
                maxWidth="670px"
                height="100vh"
                w="670px"
                mx="auto"
                py="50px"
                borderRadius="md"
                color="white"
                px="10px"
            >
           
                <Stack alignItems="flex-start" height="90%">
                    <Flex mb="46.5px" alignItems="center">
                        <Flex mr="52px">   
                            <MainTitle text={t("Brain")} />
                        </Flex>
                        <Flex alignItems="center"> 
                            <Subtitle text={t("Generative AI")} icon={<GenerativeAILogo width={24} height={24} color="#FFF" />} />
                        </Flex>
                    </Flex>
                    <Text fontWeight="bold" fontSize="20px" mb="33px" textAlign="initial">
                        {t("Try the different Generative AI that your product can work with.")}
              
                    </Text>
                    {!chatEnable ? (
                        <>
                            <form 
                                onSubmit={handleSubmit(onSubmit)}
                                style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                            >
                                <Controller
                                    name="model"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <SelectInput
                                            placeholder={t("Choose an option")} 
                                            options={providersListGenerative}
                                            onChange={field.onChange}
                                            label={t("Select provider")}
                                        />
                                    )}
                                />

                                <Controller
                                    name="system_prompt"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextAreaInput
                                            placeholder={t("You are a helpful assistant")}
                                            value={field.value}
                                            onChange={field.onChange}
                                            label={t("AI Guidelines")}
                                        />
                                    )}
                                />

                                <ActionButton 
                                    text={t("Continue")}
                                    onClick={handleSubmit(onSubmit)} 
                                    isLoading={isSubmitting}
                                    isDisabled={!selectedProviders || selectedProviders.length === 0}
                                
                                />
                            </form>
                 
                        </>

                    ) : (
                        <ChatBox sendUserMessage={sendUserMessage} showToastError={showToastError} />
                    )}
                    
                </Stack>
           
            
            </Box>
        </Flex>
    );
});

export default GenerativeAI;
