import React, {useEffect, useRef, useState} from 'react';
import './PageHome.css';
import HomeOption from "../../atoms/HomeOption/HomeOption.tsx";
import {Box, Image} from "@chakra-ui/react";
import GirlImage from '../../../assets/imgs/bckg-girl-home.png';
import MainTitle from "../MainTitle";
import ModalOptionsHome from "../ModalOptionsHome/ModalOptionsHome.tsx";
import {AIGenerate,SentimentalAnalysis, VoiceU, AIVoices,AInteraction,AIMGeneration,AICloning, Brain, Ear, Eye, Mouth} from "../../../icons";
import { useTranslation } from "react-i18next";

export interface ModalOption {
    icon: JSX.Element;  // El ícono que se va a mostrar
    title: string;      // El título de la opción
    description: string; // Descripción corta
    link:string; //link
}

interface Props {}

const PageHome: React.FC<Props> = () => {
    const { t } = useTranslation();
    const descriptionBrain = t('Try the different Generative AI that your product can work with.')
    const descriptionEar = t('See how our diverse audio transcriber works.')
    const descriptionEye = t('Describe something and let the Generative AI to generate the image.')
    const descriptionMouth = t('Make your conversational AI  speaks like whoever you want.')
    const [optionSelected, setOptionSelected]= useState<string>('');
    const [arrayOptions, setArrayOptions]=useState<ModalOption[]>([]);
    const [openModal, setOpenModal]=useState<boolean>(false);
    const [elementsHidden, setElementsHidden]=useState<boolean>(false);
    // Ref para el modal
    const modalRef = useRef<HTMLDivElement | null>(null);


    //Arreglo de opciones
    const brainOptions: ModalOption[] =[
        {
            icon: <AIGenerate/>,
            title:t('Generative AI'),
            description: t('Test different AI models by interacting with them directly.'),
            link: '/play/brain/generative-ai',
        },
        {
            icon: <SentimentalAnalysis/>,
            title:t('Sentimental analysis'),
            description: t('Identify the emotional tone of the conversation.'),
            link: '/play/brain/sentiment-analysis',
        }
    ]
    const earOptions: ModalOption[] =[
        {
            icon: <VoiceU/>,
            title:t('Voice Understanding'),
            description: t('Compare various voice-to-text transcription options to find the best fit.'),
            link: '/play/ear/voice-understanding',
        }
    ]
    const eyeOptions: ModalOption[] =[
        {
            icon: <AInteraction/>,
            title:t('Image interaction'),
            description: t('Analyze visual content using AI-powered language models.'),
            link: '/play/eyes/image-interaction',
        },
        {
            icon: <AIMGeneration/>,
            title:t('Image generation'),
            description: t('Transform text prompts into high-quality images using AI-powered language models.'),
            link: '/play/eyes/image-generation',
        }
    ]
    const mouthOptions: ModalOption[] =[
        {
            icon: <AIVoices/>,
            title:t('Ai Voices'),
            description: t('Explore and compare different AI-generated voices for your needs.'),
            link: '/play/mouth/ai-voices'
        },
        {
            icon: <AICloning/>,
            title:t('Voice Clonning'),
            description: t('Clone a voice and test it by having it speak your inputted text.'),
            link: '/play/mouth/voice-cloning'
        }
    ]

    //Asignación de título y opciones dependiendo del botón seleccionado
    const optionsToModal:{[key:string]:{title:string; options:ModalOption[]}}={
        "1": {title: 'Brain', options: brainOptions},
        "2": {title: 'Ear', options: earOptions},
        "3": {title: 'Eye', options: eyeOptions},
        "4": {title: 'Mouth', options: mouthOptions},
    }
    //función para enviar funciones y abrir modal
    const handleModal=(event:React.MouseEvent<HTMLElement>)=>{
        const optionId:string|null =event.currentTarget.getAttribute("data-id");
        //valida que optionId tenga valor
        if(optionId != null){
            const seletedOption = optionsToModal[optionId];
            if(seletedOption){
                setOptionSelected(seletedOption.title);
                setArrayOptions(seletedOption.options);
            }
        }

        //
        setOpenModal(true);
        setElementsHidden(true)
    }
    // maneja evento para cerrar el modal
    const closeModal =()=>{
        setOpenModal(false);
        setElementsHidden(false)
    }

    // useEffect para controlar los clicks fuera del boton de cerrar del modal
    useEffect(() => {
        const clickOutside = (event:MouseEvent)=>{
            if (modalRef.current && !modalRef.current.contains(event.target as Node)){
                closeModal();
            }
        }
        //Agrega el evento si el modal esta abierto
        if(openModal){
            document.addEventListener('click',clickOutside);
        }
        //quita el evento cuando se cierra el modal
        return()=>{
            document.removeEventListener('click',clickOutside);
        }
    },[openModal]);

    return (
        <div className="wrapper-home-page">
            <Box className='wrapper-girl-img'>
                {!elementsHidden &&
                    <Box className="home-options-wrapper left">
                        <Box data-id="1" className="inner-option" onClick={handleModal} cursor={'pointer'}>
                            <MainTitle text={t('Brain')}/>
                            <HomeOption description={descriptionBrain} icon={<Brain color={'#fff'} />}/>
                        </Box>

                        <Box  data-id="2"  onClick={handleModal} cursor={'pointer'} className="inner-option">
                            <MainTitle text={t('Ear')}/>
                            <HomeOption description={descriptionEar} icon={<Ear color={'#fff'} />}/>
                        </Box>
                    </Box>
                }
                <Image src={GirlImage}/>
                {!elementsHidden &&
                    <Box  className="home-options-wrapper right" >
                        <Box  data-id="3"  onClick={handleModal} cursor={'pointer'} className="inner-option">
                            <MainTitle text={t('Eye')}/>
                            <HomeOption description={descriptionEye} icon={<Eye color={'#fff'} />}/>
                        </Box>
                        <Box  data-id="4"  onClick={handleModal} cursor={'pointer'} className="inner-option">
                            <MainTitle text={t('Mouth')}/>
                            <HomeOption description={descriptionMouth} icon={<Mouth color={'#fff'} />}/>
                        </Box>
                    </Box>
                }
                <ModalOptionsHome isOpen={openModal} onClose={closeModal} optionTitle={optionSelected} options={arrayOptions}/>
            </Box>
        </div>
    );
}
export default PageHome;