import React, { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
    Box,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Text,
    VStack,
    Grid,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { GoPerson } from "react-icons/go";
import {FiDollarSign, FiExternalLink} from "react-icons/fi";
import { AiOutlineLogout } from "react-icons/ai";

import { getIconForName} from "./utils";
import { v4 as uuidv4 } from 'uuid';
import { items } from "./mocks";
import { useLogto } from "@logto/react";
import {authStore} from "../../../store/authStore.tsx";
import {LogoCanaia, MenuContent} from "../../../icons";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useCheckAuth } from "../../../hooks/useCheckAuth.tsx";

const Navbar: React.FC = () => {
    const {signOut} = useLogto();
    const {userName} = authStore();
    const navigate = useNavigate();
    const { t } = useTranslation();
    //const {subscriptionData} = useContext(AuthContext) || {}; // Asegura que `AuthContext` no sea null
    const { stateScope } = useCheckAuth();

    const LogOut = () => {
        signOut(window.location.origin + "/").then()
    }

    const authContext = useContext(AuthContext);
    if (!authContext) {
        return null;
    }


    const renderItems = () => {
        return <Grid templateColumns="repeat(3, 1fr)" gap={1}>
            {items.map(item => (
                <MenuItem
                    key={uuidv4()}
                    as="div"
                    p={5}
                    borderRadius="full"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    w="100%"

                    _hover={{ bg: "transparent", cursor: "pointer"}}
                    _focus={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    onClick={() => window.location.href = item.link}
                >
                    <Box
                        fontSize="2xl"
                        color="blue.500"
                        as="a"
                        href={item.link}>
                        {getIconForName(item.name)}
                    </Box>
                    <Text mt={2}>{item.name}</Text>
                </MenuItem>
            ))}
        </Grid>
    };
    return (
        <Box 
            bg="white" 
            boxShadow="sm" 
            pt="12px" 
            pb="12px"
            paddingX={{ base: "10px", md: "80px" }}
        >
            <Flex justify="space-between" align="center">
                <Flex align="center">
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label="App Menu"
                            icon={<MenuContent />}
                            variant="ghost"
                            fontSize="2xl"
                            marginRight={12}
                            width="32px"
                            height="32px"
                            color="blue.600"
                            _hover={{ bg: "transparent" }}
                            _focus={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                        />
                        <MenuList px={4} borderRadius="lg" boxShadow="lg" bg="white" >
                            <Flex wrap="wrap" maxW="270px" justify="flex-start">
                                {renderItems()}
                            </Flex>
                        </MenuList>
                    </Menu>
                    <Box as="a" onClick={()=> {navigate('/home')}} cursor="pointer">
                        <LogoCanaia />
                    </Box>
                </Flex>
                <Menu>
                    <MenuButton>
                        <VStack align="flex-end" spacing={0}>
                            <Flex align="center" gap="14px">
                                <GoPerson size={'24px'} />
                                <Text fontSize="md" fontWeight="medium" marginRight={2}>
                                    {userName}
                                </Text>
                                <ChevronDownIcon />
                            </Flex>
                        </VStack>
                    </MenuButton>
                    <MenuList>
                        <MenuItem as={"a"} target={"_blank"} display="flex" alignItems="center"  onClick={() => window.location.href = 'https://staging.hub.canaia.ai/settings'}>
                            <Box
                                fontSize="24px"
                                color="black"
                                mr={2}
                                display="flex"
                                alignItems="center"
                            >
                                <FiExternalLink />
                            </Box>
                            <Text fontSize="md" color="black">
                                {t("Settings")}
                            </Text>

                        </MenuItem>
                        {
                            stateScope.some(scope => scope.includes('billing'))
                            &&
                        <MenuItem as={'a'} display="flex" alignItems="center"  onClick={() => navigate('/subscription')}>
                            <Box
                                fontSize="24px"
                                color="black"
                                mr={2}
                                display="flex"
                                alignItems="center"
                            >
                                <FiDollarSign />
                            </Box>
                            <Text fontSize="md" color="black">
                                {t("Subscription")}
                            </Text>

                        </MenuItem>
                        }
                        <MenuItem as={'a'} display="flex" alignItems="center"  onClick={LogOut}>
                            <Box
                                fontSize="24px"
                                color="black"
                                mr={2}
                                display="flex"
                                alignItems="center"
                            >
                                <AiOutlineLogout/>
                            </Box>
                            <Text fontSize="md" color="black">
                                {t("Logout")}
                            </Text>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Flex>

        </Box>
    );
};

export default Navbar;
