import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Flex, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useState } from "react";
import Navbar from "./Navbar";
import { MainContainer } from "./MainContainer";
import SidebarMenu from "./SidebarMenu";
import CustomBackgroundContainer from "../Components/CustomerBackgroundContainer";
import CustomWrapper from "../atoms/CustomWrapper/CustomWrapper";
import HomeTitle from "../atoms/HomeTitle/HomeTitle";
import PageHome from "../Components/HomePage/PageHome";
import Pricing from "../../pages/Pricing";
import { SubscriptionBanner } from "../Components/SubscribeBanner";
import { useTranslation } from "react-i18next";
import { useCheckAuth } from "../../hooks/useCheckAuth";

const Layout = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { subscriptionData, isLoadingSubscription } = useCheckAuth();


    const isHomeRoute = pathname === "/home";
    const isPricingRoute = pathname === "/subscription";


    const showSubscriptionBanner =
    !subscriptionData?.subscription ||
    !subscriptionData.subscription.type ||
    new Date() > new Date(subscriptionData.subscription.period_end);

    const handleRenew = () => {
        navigate("/subscription");
    };

    return (
        <>
            <Navbar />
            {!isLoadingSubscription && showSubscriptionBanner && (
                <SubscriptionBanner onRenew={handleRenew} />
            )}

            <MainContainer>
                <Flex
                    width="100%"
                    flexDirection={isPricingRoute ? "column" : "row"}
                    alignItems="stretch"
                >
                    {!isPricingRoute && (
                        <Box
                            width={isMenuOpen ? "250px" : "80px"}
                            transition="width 0.4s ease"
                            position="relative"
                        >
                            <SidebarMenu
                                isMenuOpen={isMenuOpen}
                                toggleMenu={toggleMenu}
                                currentPath={pathname}
                            />
                            <IconButton
                                icon={isMenuOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                onClick={toggleMenu}
                                position="absolute"
                                top="50px"
                                right="-15px"
                                size="sm"
                                aria-label="Toggle Menu"
                                bg="white"
                                boxShadow="md"
                                zIndex={2}
                            />
                        </Box>
                    )}

                    {isHomeRoute ? (
                        <CustomWrapper>
                            <HomeTitle title={t("What kind of AI would you like to create?")} />
                            <PageHome />
                        </CustomWrapper>
                    ) : isPricingRoute ? (
                        <Pricing />
                    ) : (
                        <CustomBackgroundContainer>
                            <Outlet />
                        </CustomBackgroundContainer>
                    )}
                </Flex>
            </MainContainer>
        </>
    );
};

export default Layout;
