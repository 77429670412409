import { Text,Flex } from "@chakra-ui/react";
import { Select } from '@canaia/ui-kit';
import React from "react";
import { SelectInputProps } from './interfaces';

const SelectInput: React.FC<SelectInputProps> = ({ placeholder, options, onChange, label, disabled = false }) => {
    return (
        <Flex mb="15px" width="100%" alignItems="flex-start" flexDir="column">
            {label && (
                <Text mb="7px" fontSize="16px" color="white.50" fontWeight="normal">
                    {label}
                </Text>
            )}
            <Select 
                bg="white.50"
                color="black.300" 
                placeholder={placeholder}
                disabled={disabled}
                onChange={(e: string) => onChange(e)}
                options={options}
            />
        </Flex>
    );
};

export default SelectInput;
