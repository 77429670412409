import  React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const VoiceClonning: React.FC<Props> = ({color='#fff',height=57,width=57}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M33.0925 53.887V50.2945C37.8548 50.2891 42.4205 48.3949 45.7879 45.0275C49.1554 41.66 51.0496 37.0943 51.055 32.332H54.6475C54.6412 38.0468 52.3681 43.5257 48.3272 47.5667C44.2862 51.6076 38.8073 53.8807 33.0925 53.887Z"
                fill={color}/>
            <path
                d="M33.0925 46.702V43.1095C35.9499 43.1064 38.6894 41.9699 40.7099 39.9494C42.7304 37.9289 43.8669 35.1894 43.87 32.332H47.4625C47.4582 36.1418 45.9428 39.7944 43.2488 42.4883C40.5549 45.1823 36.9023 46.6977 33.0925 46.702Z"
                fill={color}/>
            <path
                d="M33.0925 39.517V35.9245C34.045 35.9234 34.9581 35.5446 35.6316 34.8711C36.3051 34.1976 36.6839 33.2845 36.685 32.332H40.2775C40.2755 34.237 39.5179 36.0634 38.1709 37.4104C36.8239 38.7574 34.9975 39.515 33.0925 39.517Z"
                fill={color}/>
            <path
                d="M18.7224 3.59277C14.4363 3.59753 10.3271 5.30228 7.29639 8.33301C4.26566 11.3637 2.56091 15.4729 2.55615 19.759H6.14865C6.14865 16.4243 7.47338 13.2261 9.83142 10.868C12.1895 8.51 15.3876 7.18527 18.7224 7.18527C22.0572 7.18527 25.2553 8.51 27.6134 10.868C29.9714 13.2261 31.2962 16.4243 31.2962 19.759C31.3025 21.9852 30.7252 24.1742 29.6218 26.1077C28.5184 28.0412 26.9274 29.6517 25.0075 30.7787L24.1112 31.2962V36.8164C24.1198 37.5369 23.9807 38.2516 23.7023 38.9162C23.424 39.5809 23.0124 40.1815 22.4929 40.6808C21.4568 41.7957 20.1064 42.5696 18.6207 42.8999C17.135 43.2303 15.584 43.1017 14.173 42.531C12.8784 41.9713 11.7721 41.0508 10.9862 39.8795C10.2004 38.7082 9.76815 37.3355 9.74115 35.9253H6.14865C6.18096 38.0341 6.81965 40.0891 7.98844 41.8447C9.15723 43.6003 10.8067 44.9823 12.7398 45.8257C14.0506 46.4002 15.466 46.6976 16.8972 46.6992C19.9131 46.6358 22.7907 45.4227 24.9417 43.3079C25.8215 42.4722 26.5205 41.4649 26.9955 40.3484C27.4706 39.2319 27.7116 38.0298 27.7037 36.8164V33.3274C29.9199 31.8262 31.734 29.804 32.9867 27.4384C34.2394 25.0728 34.8925 22.4359 34.8886 19.759C34.8839 15.4729 33.1791 11.3637 30.1484 8.33301C27.1177 5.30228 23.0085 3.59753 18.7224 3.59277Z"
                fill={color}/>
            <path
                d="M17.4293 14.5183C18.2237 14.3241 19.0519 14.3129 19.8513 14.4855C20.6507 14.6581 21.4004 15.01 22.0439 15.5147C22.6874 16.0194 23.2079 16.6636 23.566 17.3989C23.9242 18.1341 24.1106 18.9411 24.1114 19.7589H27.7038C27.7068 18.4083 27.4038 17.0746 26.8176 15.8579C26.2314 14.6411 25.3772 13.573 24.3192 12.7336C23.2497 11.8864 22.0027 11.2919 20.6712 10.9943C19.3398 10.6968 17.9583 10.704 16.63 11.0153C15.0104 11.3912 13.5287 12.2136 12.353 13.3893C11.1774 14.5649 10.3549 16.0467 9.97899 17.6662C9.5428 19.5281 9.70839 21.4804 10.452 23.2423C11.1955 25.0041 12.4787 26.4847 14.117 27.4711C14.9564 27.9451 15.6573 28.6303 16.1501 29.4588C16.6429 30.2873 16.9105 31.2303 16.9264 32.1941V35.9251H20.5189V32.1941C20.506 30.6085 20.0787 29.0539 19.2792 27.6845C18.4798 26.3151 17.336 25.1787 15.9616 24.388C15.042 23.8412 14.3059 23.0331 13.847 22.0666C13.388 21.1002 13.227 20.019 13.3844 18.9608C13.5417 17.9025 14.0104 16.915 14.7307 16.1239C15.451 15.3328 16.3904 14.7739 17.4293 14.5183Z"
                fill={color}/>
        </svg>

    );
};

export default VoiceClonning;