import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import {LogtoProvider, LogtoConfig, UserScope} from '@logto/react'
import {BrowserRouter} from "react-router-dom";
import {ChakraProvider} from "@chakra-ui/react";
import { CanaiaProvider } from '@canaia/ui-kit';
import theme from "./theme/theme.ts";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import './translator/i18n.ts'

const logtoConfig: LogtoConfig = {
    endpoint: import.meta.env.VITE_LOGTO_ENDPOINT,
    appId: import.meta.env.VITE_LOGTO_APP_ID,
    scopes: [
        "all", "admin",
        "write:organizations",
        "read:organizations",
        "write:billing",
        UserScope.Email,
        UserScope.CustomData,
        UserScope.Profile,
        UserScope.Organizations,
    ],
    resources: [
        import.meta.env.VITE_LOGTO_API_ID
    ]
}

const queryClient = new QueryClient({
    defaultOptions: {queries: {staleTime: 10000}}
})

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            'potio-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
                customer?: string;
                action?: string;
                'data-key'?: string;
                'customer-email'?: string;
            }
        }
    }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <LogtoProvider config={logtoConfig}>
                <ChakraProvider theme={theme}>
                    <CanaiaProvider>
                        <BrowserRouter>
                            <App/>
                        </BrowserRouter>
                    </CanaiaProvider>
                </ChakraProvider>
            </LogtoProvider>
        </QueryClientProvider>
    </React.StrictMode>,
)
