import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const AIGeneration: React.FC<Props> = ({color='#fff',height=57,width=57}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.54998 2.8501C5.40192 2.8501 2.84998 5.40196 2.84998 8.5501V19.9501C2.84998 23.0982 5.40192 25.6501 8.54998 25.6501H22.8C25.948 25.6501 28.5 23.0982 28.5 19.9501V8.5501C28.5 5.40196 25.948 2.8501 22.8 2.8501H8.54998ZM9.97498 17.1001H21.375C22.162 17.1001 22.8 17.7382 22.8 18.5251C22.8 19.312 22.162 19.9501 21.375 19.9501H9.97498C9.18795 19.9501 8.54998 19.312 8.54998 18.5251C8.54998 17.7382 9.18795 17.1001 9.97498 17.1001ZM8.54998 9.9751C8.54998 9.18816 9.18795 8.5501 9.97498 8.5501H21.375C22.162 8.5501 22.8 9.18816 22.8 9.9751C22.8 10.762 22.162 11.4001 21.375 11.4001H9.97498C9.18795 11.4001 8.54998 10.762 8.54998 9.9751Z"
                fill={color}/>
            <path
                d="M8.54993 39.9003V28.5003H11.3999V39.9003C11.3999 40.9625 11.6903 41.9563 12.1962 42.8076L25.5036 29.7315C27.1669 28.0974 29.8329 28.0974 31.4961 29.7315L44.8036 42.8076C45.3095 41.9563 45.5999 40.9625 45.5999 39.9003V17.1003C45.5999 13.9522 43.048 11.4003 39.8999 11.4003H31.3499V8.55029H39.8999C44.6221 8.55029 48.4499 12.3782 48.4499 17.1003V39.9003C48.4499 44.6225 44.6221 48.4503 39.8999 48.4503H17.0999C12.3779 48.4503 8.54993 44.6225 8.54993 39.9003ZM17.0999 45.6003H39.8999C40.9519 45.6003 41.9374 45.3153 42.7833 44.8183L29.4986 31.7644C28.9442 31.2198 28.0556 31.2198 27.5012 31.7644L14.2164 44.8183C15.0623 45.3153 16.0479 45.6003 17.0999 45.6003Z"
                fill={color}/>
            <path
                d="M35.625 25.6501C33.264 25.6501 31.35 23.736 31.35 21.3751C31.35 19.0142 33.264 17.1001 35.625 17.1001C37.9859 17.1001 39.9 19.0142 39.9 21.3751C39.9 23.736 37.9859 25.6501 35.625 25.6501ZM37.05 21.3751C37.05 20.5882 36.4121 19.9501 35.625 19.9501C34.8378 19.9501 34.2 20.5882 34.2 21.3751C34.2 22.162 34.8378 22.8001 35.625 22.8001C36.4121 22.8001 37.05 22.162 37.05 21.3751Z"
                fill={color}/>
        </svg>

    );
}

export default AIGeneration;