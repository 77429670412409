import MainTitle from "../../../../ui/Components/MainTitle";
import {Box, Button, Flex, Stack, Text} from "@chakra-ui/react";
import Subtitle from "../../../../ui/Components/SubtitleSeccion";
import {useEffect, useState} from "react";
import {AIVoices} from "../../../../icons";
import {Provider, providerListVoices, optionsListVoices} from './mocks.ts';
import {useNavigate} from "react-router-dom";
import {useToast} from "@chakra-ui/react";
import AudioPlayer from "../../../../ui/atoms/AudioPlayer/AudioPlayer.tsx";
import DownloadButton from "../../../../ui/atoms/DownloadButton/DownloadButton.tsx";

//
import CustomSelect from "../../../../ui/atoms/CustomSelect/CustomSelect.tsx";
import CustomForm from "../../../../ui/atoms/CustomForm/CustomForm.tsx";
import CustomLatency from "../../../../ui/Components/CustomLatency/CustomLatency.tsx";
import base64ToBlob from "../../../../utils/base64ToBlob";
import './ia-voices.css';
import { useTranslation } from "react-i18next";
import TextAreaInput from "../../../../ui/Components/TextAreaInput";

export const AiVoices = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    //
    const [selectedProvider,setSelectedProvider] = useState<Provider>(undefined);
    const [selectedVoice, setSelectedVoice] = useState<string>('');
    const [systemPrompt, setSystemPrompt] = useState<string>('');
    const [listVoices, setListVoices] = useState<{label:string; value:string}[]>([]);
    const [processingTime, setProcessingTime] = useState('');

    const [audioUrl, setAudioUrl] = useState<string | null>(null);

    const toast = useToast();

    useEffect(() => {
        if(selectedProvider) {
            setListVoices(optionsListVoices[selectedProvider]);
        }else{
            setListVoices([]);
        }
    }, [selectedProvider]);

    //  Function to handle change of  provider
    const handleProviderChange = (newProvider:string) => {
        setSelectedProvider(newProvider ===''? undefined: newProvider as Provider);
        setSelectedVoice(''); // Reset voice option when change the provider
    };
    // Handle change in Textarea value
    const handleTextareaChange = (value:string) => {
        setSystemPrompt(value); // Update systemPrompt state
    };

    //
    const isFormValid = selectedProvider && selectedVoice && systemPrompt.trim() !== '';

    // Handle change in the voice (from CustomSelect)
    const handleVoiceChange = (newVoice: string) => {
        setSelectedVoice(newVoice); // Update selected voice state
    };
    //submit success
    const handleSubmitSuccess = (response: { audio: string; stats: { processing_time_ms: number } }) => {

        //covert and save the time to string
        const processingTimeSec = (response.stats.processing_time_ms / 1000).toFixed(2);
        setProcessingTime(processingTimeSec);

        // Convert Base64 a Blob
        const audioBlob = base64ToBlob(response.audio, 'audio/wav'); // MIME to audio

        // URL to play
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);

        // Hook to show toast
        toast({
            title: "Form submitted successfully",
            description: "Your data was sent successfully.",
            status: "success",
            duration: 5000, // time to show toast
            isClosable: true,
            position: "top-right",
        });
    };

    const handleSubmitError = (error: string) => {
        console.error('Error during form submission:', error);

        toast({
            title: "An error occurred",
            description: "There was a problem processing your request.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-right"
        });
    };

    //return home function
    const backNavigate = () => {
        navigate('/');
    }

    return (
        <Flex flexDir="column" className={'wrapper-ia-voices'}>
            <Text
                fontFamily="Lato-regular"
                fontSize="18px"
                as="a"
                color="white.50"
                onClick={backNavigate}
                className="back-button"
            >
                {t("< back")}
            </Text>
            <Box
                maxWidth="670px"
                height="100vh"
                w="670px"
                mx="auto"
                py="50px"
                borderRadius="md"
                color="white"
                px="10px"
            >

                <Stack alignItems="flex-start" height="90%">
                    <Flex mb="46.5px" alignItems="center">
                        <Flex mr="52px">
                            <MainTitle text={t("Mouth")} />
                        </Flex>
                        <Flex alignItems="center">
                            <Subtitle text={t("AI Voices")} icon={<AIVoices width={24} height={24} color="#FFF" />} />
                        </Flex>
                    </Flex>
                    <Text fontWeight="bold" fontSize="20px" mb="33px">
                        {t("Select the voice that suit your company or product.")}
                    </Text>

                    <CustomForm actionUrl={'/api/experiments/text-to-speech'}
                        method="POST"
                        onSubmitSuccess={handleSubmitSuccess}
                        onSubmitError={handleSubmitError}
                        formData={{model: selectedVoice,text: systemPrompt}}
                    >
                        {/* Select provider */}
                        <CustomSelect
                            name={'provider'}
                            onChange={handleProviderChange}
                            options={providerListVoices.map(provider => ({
                                label: provider.name,
                                value: provider.value,
                            }))}
                            value={selectedProvider || ""} // Aseguramos que el valor no sea undefined
                            text={t('Choose a provider')}
                            textLabel={t('Select provider')}
                        />

                        {/* Select voice based on the selected provider */}
                        <CustomSelect
                            name={'voice'}
                            onChange={handleVoiceChange}
                            options={listVoices.map(voice => ({
                                label: voice.label,
                                value: voice.value,
                            }))}
                            text={t('Choose a voice')}
                            value={selectedVoice}
                            textLabel={t("Select voice")}
                        />

                        <TextAreaInput
                            placeholder={t("Type your text...")}
                            value={systemPrompt}
                            onChange={handleTextareaChange}
                            label={t("Text to convert to speech")}
                        />


                        <Flex className={'wrapper-btn-submit'}>
                            <Button
                                className={`${isFormValid ? 'valid'  : 'invalid'}`}
                                type="submit"
                            >
                                {t("Generate")}
                            </Button>
                        </Flex>
                    </CustomForm>

                    {audioUrl ? (
                        <>
                            <Text className={'title-audio'}>Your AI voice</Text>
                            <AudioPlayer audioURL={audioUrl} key={audioUrl} />
                            <DownloadButton audioUrl={audioUrl} fileName="generated_audio.wav"/>
                            <CustomLatency title={'Latency'} latency={processingTime}/>
                        </>
                    ) : null}
                </Stack>
            </Box>
        </Flex>
    )
}
