function getSentimentColor(sentiment: string) {
    const trimmedSentiment = sentiment.trim().toLowerCase()
    switch (trimmedSentiment) {
    case "positive":
        return "#17ba00";
    case "neutral":
        return "#d6cc07";
    case "negative":
        return "#d60c06";
    default:
        return "gray.500";
    }
}

export {getSentimentColor}