import React, { useEffect, useRef, useState } from "react";
import "./stylesChatBox.css";
import {
    Box,
    Flex,
    Text,
    Input,
    IconButton,
    VStack,
    HStack,
} from "@chakra-ui/react";
import { FiSend } from "react-icons/fi";
import { useStreamingResponse } from "../../../hooks/streamingChat/useStreamingResponse";
import {ChatBoxProps, contentMessage } from './interfaces'
import { renderMessageContent } from "../../../hooks/streamingChat/utils";
import { useTranslation } from "react-i18next";

const ChatBox: React.FC<ChatBoxProps> = ({
    showToastError,
    sendUserMessage,
}) => {
    const [input, setInput] = useState("");
    const { t } = useTranslation();
    const [conversation, setConversation] = useState<contentMessage[]>([]);
    const [botTypingMessage, setBotTypingMessage] = useState<string>("");
    const [lastMessageBot, setLastMessageBot] = useState<contentMessage | null>(null);
    const [shouldStream, setShouldStream] = useState(false);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [shouldProcessAfterConversationUpdate, setShouldProcessAfterConversationUpdate] = useState(false);
    
    const { startStreaming, partialResponse } = useStreamingResponse({
        url: `${import.meta.env.VITE_API_ENDPOINT}/api/experiments/large-language-model`,
        body: {
            model: sendUserMessage.model,
            system_prompt: sendUserMessage.system_prompt,
            temperature: 0.5,
            messages: conversation,
        },
        onComplete: (finalMessage) => {
            setLastMessageBot({ role: "assistant", content: finalMessage })
            setBotTypingMessage(finalMessage)
        },
        showToastError,
    });

    useEffect(() => {
        if (partialResponse) {
            setBotTypingMessage((prev) => prev + partialResponse);
        }
    }, [partialResponse]);

    useEffect(() => {
        if (shouldStream) {
            startStreaming();
            setShouldStream(false); 
        }
    }, [shouldStream, startStreaming]);

    const handleSendMessage = () => {
        if (!input.trim()) return;
        const userMessage = { role: "user", content: input };
        if (lastMessageBot){
            setConversation((prev) => [...prev, lastMessageBot, userMessage]);
        } else {
            setConversation((prev) => [...prev, userMessage]);
        }
        setShouldProcessAfterConversationUpdate(true);
    };

    useEffect(() => {
        // This effect will be executed after `conversation` is updated.
        if (shouldProcessAfterConversationUpdate) {
            startStreaming();
            setBotTypingMessage("");
            setInput("");
            setShouldStream(true);
    
            setShouldProcessAfterConversationUpdate(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversation, shouldProcessAfterConversationUpdate]);
    
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [conversation, botTypingMessage]);

    return (
        <Box
            maxW="900px"
            w="100%"
            bg="gray.700"
            borderRadius="md"
            p={4}
            boxShadow="md"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            maxHeight="680px"
        >
            {conversation.length === 0 
                && 
                <Flex
                    w="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    maxHeight="680px"
                >
                    <Text fontWeight="normal" fontSize="20px" mb="33px" textAlign="center">
                        {t("How can I help you?")}
                    </Text>
                </Flex>
            }
            <VStack
                spacing={4}
                align="start"
                overflowY="auto"
                flex="1"
                mb={4}
                paddingRight="4px"
                width="100%"
                ref={chatContainerRef}
                className="custom-scrollbar"
            >
           
                {conversation.map((msg, index) => (
                    msg.content && (
                        <HStack
                            key={index}
                            alignSelf={msg.role === "user" ? "flex-end" : "flex-start"}
                            maxWidth="80%"
                        >
                            <Box
                                bg={msg.role === "user" ? "blue.600" : "gray.600"}
                                color="white"
                                px={3}
                                py={2}
                                borderRadius="lg"
                                textAlign="left"
                            >
                                <Text fontSize="sm" whiteSpace="pre-wrap">
                                    {renderMessageContent(msg.content)}
                                </Text>
                            </Box>
                        </HStack>
                    )
                ))}
                {botTypingMessage && (
                    <HStack alignSelf="flex-start" maxWidth="80%">
                        <Box
                            bg="gray.600"
                            color="white"
                            px={3}
                            py={2}
                            borderRadius="lg"
                            textAlign="left"
                        >
                            <Text fontSize="sm" whiteSpace="pre-wrap">
                                {renderMessageContent(botTypingMessage)}
                            </Text>
                        </Box>
                    </HStack>
                )}
            </VStack>
            <Flex alignItems="center" mt={2}>
                <Input
                    placeholder={t("Your message...")}
                    bg="gray.800"
                    color="white"
                    borderRadius="full"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
                />
                <IconButton
                    aria-label={t("Send message")}
                    icon={<FiSend />}
                    colorScheme="blue"
                    borderRadius="full"
                    ml={2}
                    onClick={handleSendMessage}
                />
            </Flex>
        </Box>
    );
};

export default ChatBox;
