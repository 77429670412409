import React from 'react';
import './HomeOption.css'
import {Box, Text} from "@chakra-ui/react";

interface Props {
    description: string;
    icon: React.ReactNode;

}

const HomeOption : React.FC<Props> = ({ description, icon}) => {
    return (
        <Box className="home-option">
            <Box className='icon'>
                {icon}
            </Box>
            <Text className={'description'}>{description}</Text>
        </Box>
    );
}

export default HomeOption;
