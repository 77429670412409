import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const aiGenerate:React.FC<Props> = ({color='#fff',height=57,width=57}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4399_16903)">
                <path
                    d="M32.6562 37.8125V34.375H34.375V22.3438H32.6562V18.9062H39.5312V22.3438H37.8125V34.375H39.5312V37.8125H32.6562Z"
                    fill={color}/>
                <path
                    d="M26.6415 37.8125H30.079L24.0625 18.9062H18.9063L12.8965 37.8125H16.3319L17.3663 34.375H25.5786L26.6415 37.8125ZM18.4006 30.9375L21.1994 21.6372L21.6393 21.633L24.5157 30.9377L18.4006 30.9375Z"
                    fill={color}/>
                <path d="M55 6.875H48.125V0H44.6875V6.875H37.8125V10.3125H44.6875V17.1875H48.125V10.3125H55V6.875Z" fill={color}/>
                <path d="M55 20.625H51.5625V24.0625H55V20.625Z" fill={color}/>
                <path d="M34.375 0H30.9375V3.4375H34.375V0Z" fill={color}/>
                <path d="M55 55H0V0H24.0625V3.4375H3.4375V51.5625H51.5625V30.9375H55V55Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_4399_16903">
                    <rect width={width} height={height} fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
}
export default aiGenerate;