import { FormValues } from "./interfaces";

function adjustDataModelsByLanguage(data: FormValues): Omit<FormValues, "language"> {
    const { language, model, ...rest } = data;
    const adjustedModels = model.map((selectedModel) => ({
        ...selectedModel,
        value: `${selectedModel.value}:${language === "español" ? "es" : "en"}`,
    }));
    return {
        ...rest,
        model: adjustedModels,
    };
}

export { adjustDataModelsByLanguage };
