import {Navigate, Route, Routes} from "react-router-dom";
import Layout from "../ui/Layout";
import ProtectedRoute from "./ProtectedRoute.tsx";
import SignInCallback from "../pages/SignInCallback.tsx";
import Pricing from "../pages/Pricing";
import { Home } from "../pages/Home";
import { AuthRedirect } from "../pages/AuthRedirect";
import  GenerativeAi  from "../pages/Play/Brain/GenerativeAi";
import { Brain } from '../pages/Play/Brain';
import  SentimentAnalysis  from "../pages/Play/Brain/SentimentAnalysis";
import { Voice } from "../pages/Play/Ear/Voice";
import { VoiceBiometry } from "../pages/Play/Ear/VoiceBiometry";
import { ImageGeneration } from "../pages/Play/Eyes/ImageGeneration";
import { ImageInteraction } from "../pages/Play/Eyes/ImageInteraction";
import { AiVoices } from "../pages/Play/Mouth/AiVoices";
import VoiceCloning  from "../pages/Play/Mouth/VoiceCloning";
import {VoiceUnderstanding} from "../pages/Play/Ear/VoiceUnderstanding";
import { useCheckAuth } from "../hooks/useCheckAuth.tsx";

const playRoutes = [
    { path: "brain/", Component: Brain },
    { path: "brain/generative-ai", Component: GenerativeAi },
    { path: "brain/sentiment-analysis", Component: SentimentAnalysis },
    { path: "ear/voice-understanding", Component: VoiceUnderstanding },
    { path: "ear/voice", Component: Voice },
    { path: "ear/voice-biometry", Component: VoiceBiometry },
    { path: "eyes/image-generation", Component: ImageGeneration },
    { path: "eyes/image-interaction", Component: ImageInteraction },
    { path: "mouth/ai-voices", Component: AiVoices },
    { path: "mouth/voice-cloning", Component: VoiceCloning }
];

function AppRoutes() {
    const { stateScope } = useCheckAuth();

    return (
        <Routes>
            <Route path="/sign-in-callback" element={<SignInCallback />} />
            <Route path="/" element={<AuthRedirect />} />
            <Route element={<ProtectedRoute />}>
                <Route element={<Layout />}>
                    {
                        stateScope.some(scope => scope.includes('billing')) 
                && <Route path="/subscription" element={<Pricing />} />
                    }
                    
                    <Route path="/home" element={<Home />} />
                    <Route path="play">
                        {playRoutes.map(({ path, Component }) => (
                            <Route key={path} path={path} element={<Component />} />
                        ))}
                        <Route path="*" element={<Navigate to="/home" replace />} />
                    </Route>
                </Route>
                <Route path="*" element={<Navigate to="/home" replace />} />
            </Route>
        </Routes>
    );
}

export default AppRoutes;
