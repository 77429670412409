import axios from "axios";

interface ResponseStats {
    processing_time_ms: number;
}

interface ModelResponse {
    transcription: string;
    stats: ResponseStats;
}

interface UnderstandingVoiceParams {
    model: string;
    audio_file: string;
}

export interface ApiResponseItem {
    name: string;
    response: ModelResponse;
}

export async function understandingVoice(
    { model, audio_file }: UnderstandingVoiceParams,
    token: string
): Promise<ApiResponseItem> {
    const requestBody = {
        model,
        audio_file,
    };

    try {

        const response = await axios.post<ModelResponse>(
            `${import.meta.env.VITE_API_ENDPOINT}/api/experiments/speech-to-text`,
            requestBody,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json;charset=UTF-8",
                },
                timeout: 100000,
            }
        );

        return { name: model, response: response.data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error("Axios Error:", error.message);
        } else {
            console.error("There was an error, please try again:", error);
        }
        throw new Error(`There was an error with the model: ${model}`);
    }
}
