import { FormValues } from "./interfaces";

const LANGUAGE_CODES: Record<string, string> = {
    español: "es",
    inglés: "en",
};

function getDataModel(data: FormValues): Omit<FormValues, "language"> {
    const { language, model, ...rest } = data;

    const languageCode = LANGUAGE_CODES[language];
    if (!languageCode) {
        throw new Error(`Unsupported language: ${language}`);
    }

    const adjustedModels = model.map((selectedModel) => {
        if (selectedModel.value === "deepgram") {

            return {
                ...selectedModel,
                value: `deepgram:nova-2-${languageCode}`,
            };
        }

        return {
            ...selectedModel,
            value: `${selectedModel.value}:${languageCode}`,
        };
    });

    return {
        ...rest,
        model: adjustedModels,
    };
}

export { getDataModel };
