import {useEffect, useState, useRef, useCallback} from "react";
import {ChatContainer,Message,MessageList,MessageInput,TypingIndicator} from "@chatscope/chat-ui-kit-react";
import {Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Image, useToast} from "@chakra-ui/react";
import {AttachmentIcon} from "@chakra-ui/icons";
import {useTranslation} from "react-i18next";
import { useGetTokenOrganization } from "../../../../../hooks/useGetTokenOrganization";


interface ImageInteractionChatProps {
    image: string | null;
    onReset: () => void;
}

interface ChatMessage {
    content: string;
    direction: "incoming" | "outgoing";
    sender: "bot" | "user";
}

function processResponse(rawText: string, accumulatedMessage: string): string {

    const lines = rawText.split("\n");
    const dataLines = lines.filter(
        (line) =>
            line.startsWith("data: ") &&
            !line.includes("event: stats") &&
            !line.includes("time_to_first_token") &&
            !line.includes("time_to_last_token")
    );
    const newContent = dataLines
        .map((line) => line.replace("data: ", ""))
        .join("");

    if (accumulatedMessage.endsWith("") || accumulatedMessage === "") {
        return accumulatedMessage + newContent;
    } else {
        return accumulatedMessage + " " + newContent;
    }
}

export const ImageInteractionChat = ({image, onReset}: ImageInteractionChatProps) => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [userMessage, setUserMessage] = useState<string>("");
    const [accumulatedMessage, setAccumulatedMessage] = useState<string>("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();

    const initialFetchRef = useRef(false);
    const toast = useToast();
    const { fetchUpdatedToken } = useGetTokenOrganization();

    const handleStream = useCallback(async (userMessage: string) => {
        setIsLoading(true);

        try {
            const token = await fetchUpdatedToken();
            if (!token) throw new Error(t("Failed to obtain access token."));
            if (!image) throw new Error(t("Image is empty"));



            const body = JSON.stringify({
                model: "openai:gpt-4o",
                system_prompt: "string",
                temperature: 0.5,
                messages: [
                    {
                        role: "user",
                        content: userMessage,
                        image: image.replace(/^data:image\/[a-z]+;base64,/, ""),
                    },
                ],
            });

            const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/api/experiments/image-understanding`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body,
            });

            const reader = response.body?.getReader();
            const decoder = new TextDecoder();

            if (!reader) throw new Error("No stream available.");

            let accumulatedChunk = "";
            let done = false;

            while (!done) {
                const result = await reader.read();
                done = result.done;
                if (done) break;

                const chunk = decoder.decode(result.value, {stream: true});
                accumulatedChunk = processResponse(chunk, accumulatedChunk);

                setAccumulatedMessage(accumulatedChunk);
            }

            setMessages((prevMessages) => [
                ...prevMessages,
                {content: accumulatedChunk, direction: "incoming", sender: "bot"},
            ]);
            setAccumulatedMessage("");
        } catch (error) {
            console.error("Error while streaming:", error);
            // Mostrar toast de error
            toast({
                title: t("Connection Error"),
                description: t("Failed to process your request. Please check your connection and try again."),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image, toast,t]);

    const handleSendMessage = () => {
        if (userMessage.trim()) {

            setMessages((prevMessages) => [
                ...prevMessages,
                {content: userMessage, direction: "outgoing", sender: "user"},
            ]);
            setAccumulatedMessage("");
            handleStream(userMessage).then();
            setUserMessage("");
        }
    };

    const handleChange = (innerText: string) => {
        setUserMessage(innerText);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true); // Abrir el modal
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Cerrar el modal
    };

    const handleRest = () => {
        if (window.confirm(t("Are you sure you want to upload a new image? This will reset the chat."))) {
            onReset();
        }
    }

    useEffect(() => {
        if (!initialFetchRef.current) {
            initialFetchRef.current = true;

            handleStream(t("What's in this image?"))
                .catch(() => {
                    toast({
                        title: t("Initial Request Failed"),
                        description: t("Unable to analyze the image. Please try again."),
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                });
        }
    }, [handleStream, toast,t]);

    return (
        <>

            <div className="chat-wrapper">

                <div style={{marginBottom: "10px", textAlign: "left", padding: "0 1.2em 0 .8em"}}>
                    <div>
                        <Button
                            leftIcon={<AttachmentIcon/>}
                            onClick={handleOpenModal}
                            isLoading={isModalOpen && isLoading}
                            disabled={isModalOpen || isLoading}
                            style={{marginBottom: "5px", border: "1px solid #fff"}}
                            colorScheme="blue"
                            bg="transparent"
                            _hover={{
                                bg: "rgba(255, 255, 255, 0.1)",
                                borderColor: "blue.300",
                                fontWeight: "bold",
                            }}
                        >
                            {t("View Image")}
                        </Button>
                    </div>

                    <Modal
                        isOpen={isModalOpen}
                        onClose={handleCloseModal}
                        size="xl"
                    >
                        <ModalOverlay/>
                        <ModalContent>
                            <ModalHeader></ModalHeader>
                            <ModalCloseButton
                                bg="blue.500"
                                color="white"
                                style={{top: "0", right: "0"}}
                                _hover={{
                                    bg: "blue.600",
                                }}
                                _focus={{
                                    boxShadow: "none"
                                }}
                            />
                            <ModalBody
                                style={{padding: "1rem 0"}}
                            >
                                {image &&
                                    <Image
                                        src={image}
                                        alt="Preview"
                                        maxWidth="100%"
                                        maxHeight="90vh"
                                        objectFit="contain"
                                        width="100%"
                                    />
                                }
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </div>
                <ChatContainer>
                    <MessageList
                        typingIndicator={
                            isLoading && <TypingIndicator content={t("The Assistant is typing...")}/>
                        }
                    >
                        {messages.map((msg, index) => (
                            <Message
                                key={index}
                                model={{
                                    message: msg.content,
                                    sentTime: "just now",
                                    direction: msg.direction,
                                    sender: msg.sender,
                                    position: "single"
                                }}
                            />
                        ))}
                        {accumulatedMessage && (
                            <Message
                                model={{
                                    message: accumulatedMessage,
                                    sentTime: "just now",
                                    direction: "incoming",
                                    sender: "bot",
                                    position: "single"
                                }}
                            />
                        )}
                    </MessageList>
                    {!isLoading &&
                        <MessageInput
                            value={userMessage}
                            onChange={handleChange}
                            onSend={handleSendMessage}
                            attachButton={false}
                            sendButton={false}
                            placeholder={t("Ask whatever you want")}
                            disabled={isLoading}
                        />
                    }
                </ChatContainer>
            </div>
            <Button
                colorScheme="red"
                onClick={handleRest}
                disabled={isModalOpen || isLoading}
                style={{marginBottom: "10px"}}
            >
                {t("Upload Another Image")}
            </Button>
        </>

    );
};
