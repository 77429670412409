import React from "react";

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const AIVoices: React.FC<Props> = ({color='#fff',height=57,width=57}) =>{
    return (
        <svg width={width} height={height} viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.5 45.1274H4.75C4.75 37.2574 11.1299 30.8774 19 30.8774C26.87 30.8774 33.25 37.2574 33.25 45.1274H28.5C28.5 39.8805 24.2466 35.6274 19 35.6274C13.7533 35.6274 9.5 39.8805 9.5 45.1274ZM43.6145 36.4895L40.2563 33.1313C43.3744 30.0134 45.1262 25.7844 45.1262 21.375C45.1262 16.9655 43.3744 12.7366 40.2563 9.61875L43.6145 6.2605C51.9605 14.6077 51.9605 28.14 43.6145 36.4871V36.4895ZM36.8956 29.773L33.5374 26.41C36.3157 23.6282 36.3157 19.1218 33.5374 16.34L36.8956 12.9746C41.5331 17.6121 41.5331 25.1308 36.8956 29.7683V29.773ZM19 28.5C13.7533 28.5 9.5 24.2466 9.5 19C9.5 13.7533 13.7533 9.5 19 9.5C24.2466 9.5 28.5 13.7533 28.5 19C28.5 21.5195 27.4992 23.936 25.7174 25.7175C23.936 27.4992 21.5196 28.5 19 28.5ZM19 14.25C16.4051 14.2526 14.2925 16.3372 14.2553 18.9319C14.2181 21.5266 16.2701 23.6709 18.8639 23.7479C21.4577 23.8248 23.6332 21.806 23.75 19.2137V20.1637V19C23.75 16.3766 21.6234 14.25 19 14.25Z"
                fill={color}/>
        </svg>
    );
}
export default AIVoices;