import React from 'react';
import './OptionModalHome.css'
import {Text} from "@chakra-ui/react";

import {Link} from "react-router-dom";


interface Props {
    image: JSX.Element,
    title: string;
    description: string;
    link: string;
}

const OptionModalHome: React.FC<Props> = ({image,title,description,link}) => {
    return(
        <Link className="option-modal-home" to={link}>
            {image}
            <Text className="option-modal-title" fontFamily={'Lato-Bold'} fontSize={'1.5rem'} textTransform={'uppercase'}>
                {title}
            </Text>
            <Text className="option-modal-description" fontFamily={'Lato-Regular'} fontSize={'1.1875rem'}>
                {description}
            </Text>
        </Link>
    );
}

export default OptionModalHome;