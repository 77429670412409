//List providers
const PROVIDERS = ['Azure', 'Google', 'AWS', 'Eleven Labs'] as const;

// Define Provider type  as one of as specific option of PROVIDERS array or 'undefined'
export type Provider = typeof PROVIDERS[number] | undefined;

// Define structure of voices options for each provider
export  type VoiceOptions = {
    Azure:{label:string; value:string}[];
    Google:{label:string; value:string}[];
    AWS:{label:string; value:string}[];
    "Eleven Labs":{label:string; value:string}[];
}

//Define options of provider list
export const providerListVoices = [
    {name: 'Azure', value: 'Azure'},
    {name: 'Google', value: 'Google'},
    {name: 'AWS', value: 'AWS'},
    {name: 'Eleven Labs', value: 'Eleven Labs'},
];

//Define options of the list voices depend on each provider
export const optionsListVoices: VoiceOptions = {
    Azure: [
        {label: 'azure:es-es-elvira-neural', value: 'azure:es-es-elvira-neural'},
        {label: 'azure:es-es-alvaro-neural', value: 'azure:es-es-alvaro-neural'},
        {label: 'azure:es-es-abril-neural', value: 'azure:es-es-abril-neural'},
        {label: 'azure:es-es-arnau-neural', value: 'azure:es-es-arnau-neural'},
        {label: 'azure:es-es-dario-neural', value: 'azure:es-es-dario-neural'},
        {label: 'azure:es-es-elias-neural', value: 'azure:es-es-elias-neural'},
        {label: 'azure:es-es-estrella-neural', value: 'azure:es-es-estrella-neural'},
        {label: 'azure:es-es-irene-neural', value: 'azure:es-es-irene-neural'},
        {label: 'azure:es-es-laia-neural', value: 'azure:es-es-laia-neural'},
        {label: 'azure:es-es-lia-neural', value: 'azure:es-es-lia-neural'},
        {label: 'azure:es-es-nil-neural', value: 'azure:es-es-nil-neural'},
        {label: 'azure:es-es-saul-neural', value: 'azure:es-es-saul-neural'},
        {label: 'azure:es-es-teo-neural', value: 'azure:es-es-teo-neural'},
        {label: 'azure:es-es-triana-neural', value: 'azure:es-es-triana-neural'},
        {label: 'azure:es-es-vera-neural', value: 'azure:es-es-vera-neural'},
        {label: 'azure:es-es-ximena-neural-1', value: 'azure:es-es-ximena-neural-1'},
        {label: 'azure:es-es-arabella-multilingual-neural-1-3', value: 'azure:es-es-arabella-multilingual-neural-1-3'},
        {label: 'azure:es-es-isidora-multilingual-neural-1-3', value: 'azure:es-es-isidora-multilingual-neural-1-3'},
    ],
    Google: [
        {label: 'google:es-neural2-a', value: 'google:es-neural2-a'},
        {label: 'google:es-neural2-b', value: 'google:es-neural2-b'},
        {label: 'google:es-neural2-c', value: 'google:es-neural2-c'},
        {label: 'google:es-neural2-d', value: 'google:es-neural2-d'},
        {label: 'google:es-neural2-e', value: 'google:es-neural2-e'},
        {label: 'google:es-neural2-f', value: 'google:es-neural2-f'},
        {label: 'google:es-polyglot-1', value: 'google:es-polyglot-1'},
        {label: 'google:es-standard-a', value: 'google:es-standard-a'},
        {label: 'google:es-standard-b', value: 'google:es-standard-b'},
        {label: 'google:es-standard-c', value: 'google:es-standard-c'},
        {label: 'google:es-standard-d', value: 'google:es-standard-d'},
        {label: 'google:es-studio-c', value: 'google:es-studio-c'},
        {label: 'google:es-studio-f', value: 'google:es-studio-f'},
        {label: 'google:es-wavenet-b', value: 'google:es-wavenet-b'},
        {label: 'google:es-wavenet-c', value: 'google:es-wavenet-c'},
        {label: 'google:es-wavenet-d', value: 'google:es-wavenet-d'},
    ],
    AWS: [
        {label: 'aws-polly:es-neural-lucia', value: 'aws-polly:es-neural-lucia'},
        {label: 'aws-polly:es-neural-sergio', value: 'aws-polly:es-neural-sergio'},
        {label: 'aws-polly:es-standard-lucia', value: 'aws-polly:es-standard-lucia'},
        {label: 'aws-polly:es-standard-conchita', value: 'aws-polly:es-standard-conchita'},
        {label: 'aws-polly:es-standard-enrique', value: 'aws-polly:es-standard-enrique'},
    ],
    "Eleven Labs": [
        {label: 'elevenlabs:martin', value: 'elevenlabs:martin'},
        {label: 'elevenlabs:sara', value: 'elevenlabs:sara'},
        {label: 'elevenlabs:carolina', value: 'elevenlabs:carolina'},
        {label: 'elevenlabs:eva', value: 'elevenlabs:eva'},
        {label: 'elevenlabs:luis', value: 'elevenlabs:luis'},
        {label: 'elevenlabs:david', value: 'elevenlabs:david'},
    ]
}