import React from "react";
import './ModalOptionsHome.css';
import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay} from "@chakra-ui/react";
import MainTitle from "../MainTitle";
import OptionModalHome  from "../../atoms/OptionModalHome/OptionModalHome.tsx";
import {ModalOption} from "../HomePage/PageHome.tsx";
import { useTranslation } from "react-i18next";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    optionTitle: string;
    options: ModalOption[];
}

const ModalOptionsHome : React.FC<Props> = ({isOpen,onClose, optionTitle, options}) => {
    const { t } = useTranslation();
    return (
        <div className="wrapper-modal-options-home">
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent className="modal-content-custom">
                    <ModalHeader className="modal-header-custom">
                        <MainTitle text={t(optionTitle)}/>
                    </ModalHeader>
                    <ModalCloseButton className={'modal-close-button'} onClick={onClose}/>
                    <ModalBody className="modal-body-custom">
                        {Object.entries(options).map(([key, {icon, title, description, link}]) => (
                            <OptionModalHome key={key} image={icon} title={title} description={description} link={link}/>
                        ))}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}
export default ModalOptionsHome;