import { Text } from "@chakra-ui/react";
import React from "react";

interface MainTitleProps {
  text: string;
}

const MainTitle: React.FC<MainTitleProps> = ({ text }) => {
    return (
        <Text fontFamily="Lato-Bold" fontSize="2xl" fontWeight="bold" color="blue.50" textTransform={"uppercase"}>
            {text}
        </Text>
    );
};

export default MainTitle;
