import { useState } from "react";
import {Box, Flex, Stack, Text, Image, Button, useToast, Spinner} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import MainTitle from "../../../../ui/Components/MainTitle";
import Subtitle from "../../../../ui/Components/SubtitleSeccion";
import ImageInteractionIcon from "../../../../icons/eyes/imageinteraction.tsx";
import SelectInput from "../../../../ui/Components/SelectInput";
import { Controller, useForm, useWatch } from "react-hook-form";
import {FormValues, imageProviders} from "./interfaces.tsx";
import TextAreaInput from "../../../../ui/Components/TextAreaInput";
import ActionButton from "../../../../ui/Components/Button";
import base64ToBlob from "../../../../utils/base64ToBlob.tsx";
import { useTranslation } from "react-i18next";
import { useGetTokenOrganization } from "../../../../hooks/useGetTokenOrganization.tsx";

export const ImageGeneration = () => {
    const { control, handleSubmit, formState: { isSubmitting } } = useForm<FormValues>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToast();
    const { fetchUpdatedToken } = useGetTokenOrganization();
    
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [imageBlob, setImageBlob] = useState<Blob | null>(null);

    const selectedProviders = useWatch({
        control,
        name: "model",
        defaultValue: ""
    });

    const systemPrompt = useWatch({
        control, name: "system_prompt", defaultValue: ""
    });

    const onSubmit = async (data: FormValues) => {

        try {
            const token = await fetchUpdatedToken();

            if (!data.model || !data.system_prompt) throw new Error(t("Data is empty"));
            if (!token) throw new Error(t("Failed to obtain access token."));

            const body = JSON.stringify(
                {
                    "model": data.model,
                    "prompt": data.system_prompt
                }
            );

            const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/api/experiments/image-generation`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body,
            });

            if (!response.ok) {
                toast({
                    title: "Error",
                    description: t("There was an error please try again"),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                throw new Error(t('Failed to generate image.'));
            }

            const responseBody = await response.json();

            if (responseBody && responseBody.image) {
                const base64Image = responseBody.image;
                const mimeType = "image/png";
                const blob = base64ToBlob(base64Image, mimeType);
                const url = URL.createObjectURL(blob);
                setImageUrl(url);
                setImageBlob(blob);
                toast({
                    title: t("Success"),
                    description: t("The image was created successfully"),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Error",
                    description: t("There was an error please try again"),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                throw new Error(t("Image not found in response"));
            }
        } catch (error) {
            console.error(t("Error generating image:"), error);
            toast({
                title: "Error",
                description: t("An error occurred while generating the image."),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const backNavigate = () => {
        navigate('/');
    };

    const downloadImage = () => {
        if (imageBlob) {
            const link = document.createElement("a");
            link.href = imageUrl!;
            link.download = "generated-image.png";
            link.click();
        }
    };

    const isButtonDisabled = !selectedProviders || systemPrompt.trim() === "";

    return (
        <Flex flexDir="column">
            <Text
                fontFamily="Lato-regular"
                fontSize="18px"
                as="a"
                color="white.50"
                onClick={backNavigate}
                className="back-button"
            >
                {t("< back")}
            </Text>
            <Box
                maxWidth="670px"
                minHeight="100vh"
                w="670px"
                mx="auto"
                py="50px"
                px="15px"
                borderRadius="md"
                position="relative"
                color="white"
            >
                <Stack alignItems="flex-start" height={"90%"}>
                    <Stack direction="row" spacing="52px" alignItems="center">
                        <Flex mb="46" alignItems="center">
                            <Flex mr="52px">
                                <MainTitle text={t("Eyes")} />
                            </Flex>
                            <Flex alignItems="center">
                                <Subtitle text={t("Image generation")} icon={<ImageInteractionIcon width={24} height={24} color="#FFF" />} />
                            </Flex>
                        </Flex>
                    </Stack>

                    <Text fontWeight="bold" fontSize="20px" mb="33px">
                        {t("Describe something and let the Generative AI generate the image.")}
                    </Text>

                    <form style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Controller
                            name="model"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <SelectInput
                                    placeholder={t("Choose an option")}
                                    options={imageProviders}
                                    onChange={field.onChange}
                                    label={t("Select provider")}
                                    disabled={isSubmitting}
                                />
                            )}
                        />

                        <Controller
                            name="system_prompt"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextAreaInput
                                    placeholder={t("Write your text...")}
                                    value={field.value}
                                    onChange={field.onChange}
                                    label={t("Instructions of the image to generate.")}
                                    disabled={isSubmitting}
                                />
                            )}
                        />

                        <ActionButton
                            text={t("Generate")}
                            onClick={handleSubmit(onSubmit)}
                            isDisabled={isButtonDisabled || isSubmitting}
                        />
                    </form>

                    {isSubmitting && (
                        <Flex justifyContent="center" width={"100%"} mt="20px">
                            <Spinner size={"xl"} />
                        </Flex>
                    )}

                    {imageUrl && (
                        <Box mt="30px" textAlign="center">
                            <Image src={imageUrl} alt="Generated AI" maxWidth="100%" height={"auto"} borderRadius="md" />
                            <Button mt="10px" colorScheme="blue" onClick={downloadImage}>
                                {t("Download Image")}
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Box>
        </Flex>
    );
};
