import React from 'react';
import './HomeTitle.css';
import {Text} from "@chakra-ui/react";

interface Props {
    title: string;
}

const HomeTitle: React.FC<Props> = ({ title }) => {
    return (
        <Text className="wrapper-home-title">
            {title}
        </Text>
    );
}

export default HomeTitle;