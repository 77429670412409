export const providersListGenerative = [
    { name: "Anthropic claude-3-haiku", value: "anthropic:claude-3-haiku" },
    { name: "Anthropic claude-3-sonnet", value: "anthropic:claude-3-sonnet" },
    { name: "Anthropic claude-opus", value: "anthropic:claude-opus" },
    { name: "Anthropic claude-3.5-sonnet", value: "anthropic:claude-3.5-sonnet" },
    { name: "Openai gpt 3.5", value: "openai:gpt-3.5-turbo" },
    { name: "Openai gpt 4-turbo", value: "openai:gpt-4-turbo" },
    { name: "Openai gpt 4o", value: "openai:gpt-4o" },
    { name: "Openai gpt 4o-mini", value: "openai:gpt-4o-mini" },
    { name: "Google gemini 1.5flash", value: "google:gemini-1.5-flash" },
    { name: "Google gemini 1.5 pro", value: "google:gemini-1.5-pro" },
    { name: "Llama 3.1 nemotron 70b", value: "llama:3.1-nemotron-70b" },
];