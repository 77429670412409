const chat = {
    ".chat-wrapper": {
        background: "#4E4D4D",
        border: "2px solid #D6D6D6",
        borderRadius: "8px",
        fontSize: "16px",
        minHeight: "240px",
        overflow: "hidden",
        padding: "1rem 0",
        width: "100%",
    },
    ".message-input": {
        borderTop: "1px solid #ccc",
    },
    ".cs-message-list": {
        background: "#4E4D4D",
    },
    ".cs-chat-container .cs-message-input": {
        background: "#4E4D4D",
        border: "0",
        padding: "1rem 0",
    },
    ".scrollbar-container": {
        background: "transparent",
    },
    ".cs-message-input__content-editor-wrapper": {
        background: "#6E6E6E",
    },
    ".cs-message-input__content-editor": {
        background: "transparent",
        color: "#fff",
        textAlign: "left"
    },
    ".cs-message--incoming .cs-message__content": {
        background: "transparent",
        padding: "1rem 0",
        color: "#fff",
        textAlign: "left",
        fontSize: "16px"
    },
    ".cs-message.cs-message--outgoing.cs-message--first .cs-message__content": {
        background: "#efefef",
        borderRadius: "20px",
        color: "#000",
    },
    ".cs-message.cs-message--incoming.cs-message--first .cs-message__content": {
        background: "transparent",
        color: "#fff",
    },
    ".cs-message.cs-message--outgoing.cs-message--single .cs-message__content": {
        background: "#fff",
        fontSize:"16px"
    },
    ".cs-message-list .cs-typing-indicator": {
        background: "transparent",
        margin: "0 1.2em 0 .8em",
        padding: "0",
    },
    ".cs-typing-indicator__text": {
        color: "#fff"
    },
    ".cs-typing-indicator__dot": {
        backgroundColor: "#fff"
    }
}

export default chat;