import React, { useRef, useEffect, useCallback } from "react";
import { Flex, Text, IconButton, useTheme } from "@chakra-ui/react";
import { FiPlay, FiPause } from "react-icons/fi";
import { useTranslation } from "react-i18next";

interface AudioPlayerProps {
  audioSrc: string;
  isPlaying: boolean;
  onPlayPause: (playing: boolean) => void;
}

const UploadedAudioPlayer: React.FC<AudioPlayerProps> = ({
    audioSrc,
    isPlaying,
    onPlayPause,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const white = theme.colors.white[50]; 
    const audioRef = useRef<HTMLAudioElement | null>(null);

    const handlePlayPause = useCallback(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
                onPlayPause(false);
            } else {
                audioRef.current.play().catch((error) => {
                    console.error("Failed to play audio:", error);
                });
                onPlayPause(true);
            }
        }
    }, [isPlaying, onPlayPause]);

    useEffect(() => {
        const audioElement = audioRef.current;
        const handleAudioEnded = () => {
            onPlayPause(false); 
        };
        if (audioElement) {
            audioElement.addEventListener("ended", handleAudioEnded);
        }
        return () => {
            if (audioElement) {
                audioElement.removeEventListener("ended", handleAudioEnded);
            }
        };
    }, [onPlayPause]);

    return (
        <Flex
            bg="#0c56c038"
            p={4}
            borderRadius="full"
            justifyContent="center"
            alignItems="center"
            gap={2}
            boxShadow="md"
            width="400px"
            cursor="pointer"
            height="50px"
            onClick={handlePlayPause}
        >
            <audio ref={audioRef} src={audioSrc} />
            <IconButton
                icon={isPlaying ? <FiPause size={22} color={white} /> : <FiPlay size={22} color={white} />}
                aria-label={isPlaying ? t("Pause Audio") : t("Play Audio")}
                colorScheme="whiteAlpha"
                variant="ghost"
                onClick={handlePlayPause}
                sx={{
                    _hover: {
                        bg: "none",
                    },
                    _active: {
                        bg: "none", 
                    },
                }}
            />
            <Text color="white" fontWeight="bold" fontSize="md">
                {isPlaying ? t("Playing...") : t("Is this audio okay?")}
            </Text>
        </Flex>
    );
};

export default UploadedAudioPlayer;
