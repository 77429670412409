import React, {createContext, useEffect, ReactNode, useState} from "react";
import {useLogto} from "@logto/react";
import {authStore} from "../store/authStore";
import {useCheckTokenExpiration} from "../hooks/useCheckTokenExpiration";
import {useNavigate} from "react-router-dom";
import { useGetTokenOrganization } from "../hooks/useGetTokenOrganization";
import i18n from "i18next";

export interface AuthContextType {
    isAuthenticated: boolean,
    userLanguage: string,
    subscriptionData: SubscriptionData | null;
    stateScope : string[];
    isLoadingSubscription: boolean;
}

export const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
    children: ReactNode;
}

interface SubscriptionData {
    subscription: { period_end: string, period_start: string, type: string } | null;
    stripe_customer_id: string;
    first_customer: boolean;
}

interface CustomData {
    language?: string;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
    const {isAuthenticated, getIdTokenClaims, getAccessTokenClaims, signOut, fetchUserInfo} = useLogto();
    const {setUserName} = authStore.getState();
    const {isTokenValid, timeUntilExpiration} = useCheckTokenExpiration();
    const [userLanguage, setUserLanguage] = useState<string>('');
    const [subscriptionData, setSubscriptionData] = useState<SubscriptionData | null>(null);
    const [stateScope, setStateScope] = useState<string[]>([]);
    const [isLoadingSubscription, setIsLoadingSubscription] = useState<boolean>(false);
    const navigate = useNavigate()
    const { fetchUpdatedToken } = useGetTokenOrganization();

    useEffect(() => {
        const checkTokenAndSignOut = async () => {
            if (isAuthenticated) {

                if (!isTokenValid) {
                    console.warn("Invalid token. Closing...");
                    await signOut(window.location.origin + "/");
                    return;
                }

                const claims = await getIdTokenClaims();
                const userScopesState = await getAccessTokenClaims(import.meta.env.VITE_LOGTO_API_ID)
                const userInfo = await fetchUserInfo();
                const customData = userInfo?.custom_data as CustomData;
                const preferredLanguage = customData?.language || navigator.language || navigator.languages[0];
                const normalizedLanguage = preferredLanguage.includes("-ES") ? "es" : preferredLanguage.split("-")[0] === "es" ? "es" : "en";

                setUserName(claims?.name || "");
                i18n.changeLanguage(preferredLanguage)
                    .catch((error)=>{ console.log('Error al cambiar el idioma:', error)});
                setUserLanguage(normalizedLanguage);
                if (userScopesState && userScopesState.scope) {
                    setStateScope(userScopesState.scope.split(' '));
                }

            }
        };

        checkTokenAndSignOut().then();
        const interval = setInterval(checkTokenAndSignOut, 10000);

        return () => clearInterval(interval);
    }, [isAuthenticated, getIdTokenClaims, setUserName, timeUntilExpiration, signOut, isTokenValid, userLanguage, fetchUserInfo, getAccessTokenClaims]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoadingSubscription(true);
            if (isAuthenticated) {
                try {
                    const token = await fetchUpdatedToken();
                    const response = await fetch(`${import.meta.env.VITE_API_ENDPOINT}/api/user/subscription`, {
                        method: "GET",
                        headers: {Authorization: `Bearer ${token}`}
                    });

                    const data = await response.json();
                    setSubscriptionData(data);
                } catch (error) {
                    console.error("Error fetching data:", error);
                } finally {
                    setIsLoadingSubscription(false);
                }
            }

        };

        fetchData().then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ getIdTokenClaims, isAuthenticated]);

    useEffect(() => {
        if (subscriptionData?.first_customer && !subscriptionData.subscription) {
            navigate("/subscription");
        }
    }, [subscriptionData, navigate]);

    return (
        <AuthContext.Provider value={{isAuthenticated, userLanguage, subscriptionData, stateScope, isLoadingSubscription }}>
            {children}
        </AuthContext.Provider>
    );
};