import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface RecordingAudioProps {
  isRecording: boolean;
  startRecording: () => void;
  stopRecording: () => void;
}

const RecordingAudio: React.FC<RecordingAudioProps> = ({
    isRecording,
    startRecording,
    stopRecording,
}) => {
    const { t } = useTranslation();
    return (
        <Flex
            bg="blue.50"
            p={4}
            borderRadius="full"
            justifyContent="center"
            alignItems="center"
            gap={2}
            boxShadow="md"
            width="400px"
            cursor="pointer"
            onClick={isRecording ? stopRecording : startRecording}
        >
            <Box
                as="span"
                w={3}
                h={3}
                borderRadius="full"
                bg={isRecording ? "red.500" : "white"}
            />
            <Text color="white" fontWeight="bold" fontSize="md">
                {isRecording ? t("Recording...") : t("Record your voice")}
            </Text>
        </Flex>
    );
};

export default RecordingAudio;
