import React from "react";

function processStreamingResponse(rawText: string): string {
    const dataRegex = /event: text\s+data: ([\s\S]*?)(?=event:|$)/g;
    let match;
    let result = ""
    while ((match = dataRegex.exec(rawText)) !== null) {
        const aux = match[1].substring(0, match[1].length - 2);
        const currentData = aux.replace(/(\r?\n)+/g, "\n");
        result += currentData;
    }
    return result;
}


function renderMessageContent(content: string): React.ReactNode {
    return content.includes("\n")
        ? content.split("\n").map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
                <br />
            </React.Fragment>
        ))
        : content
}



export { processStreamingResponse, renderMessageContent };


