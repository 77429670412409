import { useState } from "react";
import {processStreamingResponse} from './utils';
import {StreamingResponseParams} from './interface';
import { useGetTokenOrganization } from "../useGetTokenOrganization";

export const useStreamingResponse = ({
    url,
    body,
    onComplete,
    showToastError,
}: StreamingResponseParams) => {
    const [partialResponse, setPartialResponse] = useState<string>("");
    const [processWhile] = useState<boolean>(true);
    const { fetchUpdatedToken } = useGetTokenOrganization();
    

    const startStreaming = async () => {
        try {
            const token = await fetchUpdatedToken();
            if (typeof token !== 'string' || !token) {
                throw new Error("Failed to obtain access token.");
            }
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            });
            const reader = response.body?.getReader();
            const decoder = new TextDecoder("utf-8");
            if (!reader) return;

            let accumulatedMessage = "";
            let result;
            while (processWhile) {
                const { value, done } = await reader.read();
               
                if (done) break
                const chunk = decoder.decode(value || new Uint8Array(), { stream: true });
                accumulatedMessage += processStreamingResponse(chunk)
                result = processStreamingResponse(chunk)
                if (accumulatedMessage) {
                    setPartialResponse(result);
                }
            }
            onComplete(accumulatedMessage);
            setPartialResponse("");
        } catch (error) {
            console.error("Error durante el streaming:", error);
            showToastError && showToastError("An error occurred while streaming the response.");
        }
    };

    return { partialResponse, startStreaming };
};

