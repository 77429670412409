import './App.css'
import AppRoutes from "./router/AppRouter.tsx";
import { useGetAccessToken } from './hooks/useGetAccessToken.tsx';
import {AuthProvider} from "./context/AuthContext.tsx";

function App() {
    useGetAccessToken();

    return (
        <>
            <AuthProvider>
                <AppRoutes/>
            </AuthProvider>
        </>
    )
}

export default App
