import React from 'react';
import {Box} from "@chakra-ui/react";

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const LatencyIcon: React.FC<Props> = ({ color= '#2dac93',width = 24, height=24 }) => {
    return (
        <Box className={'wrapper-icon-latency'}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
                <path
                    d="M0 0 C1.32 0 2.64 0 4 0 C3.67 1.65 3.34 3.3 3 5 C3.721875 5.20625 4.44375 5.4125 5.1875 5.625 C9.07250691 7.52433671 10.59203798 9.38805697 13 13 C13.10673048 17.37594985 10.87596759 19.86258081 8 23 C4.79776203 24.60111899 1.47461286 24.49827144 -2 24 C-5 22.125 -5 22.125 -7 20 C-7 19.34 -7 18.68 -7 18 C-7.66 18 -8.32 18 -9 18 C-9.5045037 15.22522966 -9.34106788 13.52943543 -7.8046875 11.14453125 C-3.35248273 5.71910584 -3.35248273 5.71910584 1 5 C0.67 3.35 0.34 1.7 0 0 Z M-1.75 9 C-4.36945939 10.89478971 -4.36945939 10.89478971 -4.5 14.5 C-4.24307873 17.86007049 -4.24307873 17.86007049 -2.875 19.9375 C-0.11019567 21.50422245 1.88188517 21.38976435 5 21 C7 20 7 20 8 18 C8.39831203 14.81350376 8.60548083 12.8779472 6.75 10.1875 C3.5198879 7.99563822 1.99239171 7.63913029 -1.75 9 Z "
                    fill={color} transform="translate(10,0)"/>
                <path d="M0 0 C0.99 0 1.98 0 3 0 C3 1.65 3 3.3 3 5 C2.01 5 1.02 5 0 5 C0 3.35 0 1.7 0 0 Z " fill={color} transform="translate(13,12)"/>
                <path d="M0 0 C0.99 0 1.98 0 3 0 C3 1.65 3 3.3 3 5 C2.01 5 1.02 5 0 5 C0 3.35 0 1.7 0 0 Z " fill={color} transform="translate(7,12)"/>
            </svg>
        </Box>
    );
};

export default LatencyIcon;