import React, { useEffect, useRef, useState } from "react";
import { Box, Flex, Text, Stack } from "@chakra-ui/react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { voiceCloningApi } from "../../../../api/mouth/voiceCloning";
import blobToBase64 from '../../../../utils/blobTobase64';
import { useNavigate } from "react-router-dom";
import MainTitle from "../../../../ui/Components/MainTitle";
import GenerativeAILogo from "../../../../icons/brain/generativeai";
import Subtitle from "../../../../ui/Components/SubtitleSeccion"; 
import ActionButton from "../../../../ui/Components/Button";
import TextAreaInput from "../../../../ui/Components/TextAreaInput";
import SelectInput from "../../../../ui/Components/SelectInput";
import AudioUpload from "../../../../ui/Components/Audio/AudioUpload";
import RecordingAudio from "../../../../ui/Components/Audio/RecordingAudio";
import UploadedAudioPlayer from "../../../../ui/Components/Audio/UploadedAudioPlayer";
import ResponseAudioVoice from "../../../../ui/Components/Audio/ResponseAudioVoice";
import { FormValuesVoiceCloning } from '../interfaces'
import useToastError from "../../../../hooks/useToastError";
import { useTranslation } from "react-i18next";
import { useGetTokenOrganization } from "../../../../hooks/useGetTokenOrganization";


const VoiceCloning: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [audioSrc, setAudioSrc] = useState<string | null>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [base64Audio, setBase64Audio] = useState("");
    const showToastError = useToastError();
    const { fetchUpdatedToken } = useGetTokenOrganization();

    const { handleSubmit, control, watch, formState: { isSubmitting }} = useForm<FormValuesVoiceCloning>({
        defaultValues: {
            audioFile: null,
            text: "",
            model: "",
        },
    });
    const uploadedFile = watch("audioFile");

    useEffect(() => {
        // Create the url of the audio so that the user can play it.
        if (uploadedFile && uploadedFile instanceof File) {
            const audioURL = URL.createObjectURL(uploadedFile);
            setAudioSrc(audioURL);
        }
    }, [uploadedFile]);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;
            audioChunksRef.current = [];
            mediaRecorder.start();
            setIsRecording(true);
    
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };
    
            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
                const audioURL = URL.createObjectURL(audioBlob);
                setAudioSrc(audioURL);
            };
        } catch (error) {
            console.error(t("Error accessing microphone:"), error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            const tracks = mediaRecorderRef.current.stream.getTracks();
            tracks.forEach((track) => track.stop());
        }
    };
    
    const handlePlayPause = (playing: boolean) => {
        setIsPlaying(playing);
    };

    useEffect(() => {
        //Creates the reference to the inputs as soon as the component is mounted.
        const currentAudioRef = audioRef.current;
        if (currentAudioRef) {
            const handlePlaybackEnd = () => {
                setIsPlaying(false);
            };
            currentAudioRef.addEventListener("ended", handlePlaybackEnd);
            return () => {
                currentAudioRef.removeEventListener("ended", handlePlaybackEnd);
            };
        }
    }, []);
    
    const sendAudioToBackend = async (
        data: { audioFile: File | null; text: string; model: string }
    ) => {
        const { audioFile, text, model } = data;
        if (!audioFile && audioChunksRef.current.length === 0) {
            showToastError(t("There was an error with the audio."))
            return;
        }
        const audioBlob =
            audioFile instanceof File
                ? audioFile
                : new Blob(audioChunksRef.current, { type: "audio/wav" });
    
        try {
            const base64Audio = await blobToBase64(audioBlob);
            const token = await fetchUpdatedToken();
            if (!token) {
                throw new Error(t("Failed to obtain access token."));
            }
            const response = await voiceCloningApi(
                {
                    model,
                    audio: base64Audio.split(",")[1],
                    text: text || "Default text for audio cloning",
                },
                token
            );
            setBase64Audio(response.response.audio)
           
        } catch (error) {
            showToastError(t("There was an error in the request."))
        }
    };
    const handleSubmitForm: SubmitHandler<FormValuesVoiceCloning> = async (data) => {
        await sendAudioToBackend(data); 
    };

    
    const backNavigate = () => {
        navigate('/')
    }

    return (
        <Flex flexDir="column">
            <Text 
                fontFamily="Lato-regular"  
                fontSize="18px" 
                as="a"
                color="white.50" 
                onClick={backNavigate}
                className="back-button"
            >
                {t("< back")}
            </Text>
            <Box
                maxWidth="670px"
                height="auto"
                w="670px"
                mx="auto"
                py="50px"
                borderRadius="md"
                color="white"
                px="10px"
            >
                <Stack alignItems="flex-start" height="auto"> 
                    <Flex mb="46.5px" alignItems="center">
                        <Flex mr="52px">   
                            <MainTitle text={t("Mouth")} />
                        </Flex>
                        <Flex alignItems="center"> 
                            <Subtitle text={t("Voice cloning")} icon={<GenerativeAILogo width={24} height={24} color="#FFF" />} />
                        </Flex>
                    </Flex>
                    <Text fontWeight="bold" fontSize="20px" mb="33px">
                        {t("Try the different Generative AI that your product can work with.")}
                    </Text>
                    <form 
                        onSubmit={handleSubmit(handleSubmitForm)}
                        style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                    >
                        <Controller
                            name="model"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <SelectInput
                                    placeholder={t("Providers")}
                                    options={[{name: "Elevenlabs", value: "elevenlabs:standard"}]}
                                    onChange={field.onChange}
                                    label={t("Select provider")}
                                />
                               
                            )}
                        />
                     
                        <Box
                            w="100%"
                            mx="auto"
                            py="30px"
                            borderRadius="md"
                            borderColor="white"
                            border="solid 1px"
                            color="white"
                            px="50px"
                            mb="20px"
                        >

                            <Text fontSize="l" fontWeight="normal" mb={4} mt="15px">
                                {t("Upload or Record Audio")}
                            </Text>
                            <Flex direction="column" alignItems="center" mb={4}>
                                <RecordingAudio
                                    isRecording={isRecording}
                                    startRecording={startRecording}
                                    stopRecording={stopRecording}
                                />
                            </Flex>
                            <Text fontSize="xl" fontWeight="bold" mb={4}>
                                {t("or")}
                            </Text>
                            <Flex direction="column" alignItems="center" mb={4}>
                                <Controller
                                    name="audioFile"
                                    control={control}
                                    render={({ field }) => (
                                        <AudioUpload
                                            onAudioUpload={(file) => {
                                                field.onChange(file);
                                            }}
                                        />
                                    )}
                                />
                            </Flex>

                            {audioSrc && (
                                <Flex direction="column" alignItems="center" mt={4}>
                                    <UploadedAudioPlayer
                                        audioSrc={audioSrc}
                                        isPlaying={isPlaying}
                                        onPlayPause={handlePlayPause}
                                    />
                                </Flex>
                            )}
                        </Box>
                        <Controller
                            name="text"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextAreaInput
                                    placeholder={t("Write your text...")}
                                    value={field.value}
                                    onChange={field.onChange}
                                    label={t("Text to convert to audio")}
                                    height="130px"
                                />
                            )}
                        />
              
                        <Box
                            maxWidth="300px"
                            height="auto"
                            w="200px"
                            mx="auto"
                            py="50px"
                            borderRadius="md"
                            color="white"
                            px="10px"
                        >
                            <ActionButton 
                                text={t("Generate")}
                                onClick={handleSubmit(handleSubmitForm)} 
                                isLoading={isSubmitting}
                                isDisabled={ audioSrc === null ||
                                !watch("text") ||
                                watch("model").length === 0
                                }
                                
                            />
                        </Box>
                    </form>
                    {base64Audio && <ResponseAudioVoice base64Audio={base64Audio} fileName="AI_Voice.wav" />}
                </Stack>
            </Box>
        </Flex>
    );
};

export default VoiceCloning;
