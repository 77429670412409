import React from "react";

const Cloud: React.FC<{
    width?: number;
    height?: number;
    color?: string;
}> = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2672_8617)">
                <path
                    d="M10.3125 16.875L11.6344 18.1969L14.0625 15.7781V27.1875H15.9375V15.7781L18.3656 18.1969L19.6875 16.875L15 12.1875L10.3125 16.875Z"
                    fill="#38ADC7"/>
                <path
                    d="M22.0312 20.6248H21.5625V18.7498H22.0312C23.1501 18.7945 24.241 18.393 25.0638 17.6335C25.8866 16.8739 26.374 15.8187 26.4187 14.6998C26.4635 13.5809 26.0619 12.4901 25.3024 11.6672C24.5429 10.8444 23.4876 10.357 22.3687 10.3123H21.5625L21.4687 9.54353C21.2607 7.9652 20.4861 6.51629 19.2891 5.4667C18.0922 4.41711 16.5545 3.83842 14.9625 3.83842C13.3705 3.83842 11.8328 4.41711 10.6358 5.4667C9.43886 6.51629 8.66423 7.9652 8.45624 9.54353L8.43749 10.3123H7.63124C6.51236 10.357 5.45708 10.8444 4.69756 11.6672C3.93804 12.4901 3.53648 13.5809 3.58124 14.6998C3.62599 15.8187 4.11339 16.8739 4.93621 17.6335C5.75902 18.393 6.84986 18.7945 7.96874 18.7498H8.43749V20.6248H7.96874C6.46537 20.6153 5.01858 20.0503 3.90654 19.0386C2.79449 18.0269 2.09569 16.6398 1.94449 15.144C1.79328 13.6483 2.20034 12.1494 3.08741 10.9356C3.97448 9.72178 5.27894 8.87873 6.74999 8.56853C7.15472 6.68089 8.19452 4.98913 9.69591 3.77553C11.1973 2.56193 13.0694 1.8999 15 1.8999C16.9305 1.8999 18.8027 2.56193 20.3041 3.77553C21.8055 4.98913 22.8453 6.68089 23.25 8.56853C24.721 8.87873 26.0255 9.72178 26.9126 10.9356C27.7996 12.1494 28.2067 13.6483 28.0555 15.144C27.9043 16.6398 27.2055 18.0269 26.0934 19.0386C24.9814 20.0503 23.5346 20.6153 22.0312 20.6248Z"
                    fill="#38ADC7"/>
            </g>
            <defs>
                <clipPath id="clip0_2672_8617">
                    <rect width="30" height="30" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default Cloud;
