import  React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const sentimentalAnalysis : React.FC<Props> = ({color='#fff',height=57,width=57}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 59 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29.5 34.1286C27.4252 34.1304 25.3823 33.618 23.5539 32.6373C21.7256 31.6566 20.1687 30.2381 19.0225 28.5087L22.015 26.5195C22.8344 27.7546 23.9467 28.7677 25.2527 29.4685C26.5587 30.1693 28.0178 30.5361 29.5 30.5361C30.9821 30.5361 32.4413 30.1693 33.7473 29.4685C35.0533 28.7677 36.1656 27.7546 36.985 26.5195L39.9775 28.5087C38.8313 30.2381 37.2744 31.6566 35.446 32.6373C33.6177 33.618 31.5748 34.1304 29.5 34.1286Z"
                fill={color}/>
            <path
                d="M36.685 14.37C35.9745 14.37 35.2799 14.5807 34.6891 14.9755C34.0984 15.3702 33.6379 15.9313 33.366 16.5878C33.0941 17.2442 33.0229 17.9665 33.1616 18.6634C33.3002 19.3603 33.6423 20.0004 34.1448 20.5028C34.6472 21.0052 35.2873 21.3474 35.9842 21.486C36.681 21.6246 37.4034 21.5535 38.0598 21.2816C38.7163 21.0097 39.2773 20.5492 39.6721 19.9584C40.0668 19.3676 40.2775 18.6731 40.2775 17.9625C40.2822 17.4895 40.1924 17.0202 40.0136 16.5823C39.8347 16.1443 39.5702 15.7464 39.2357 15.4119C38.9012 15.0774 38.5033 14.8129 38.0653 14.634C37.6273 14.4551 37.1581 14.3654 36.685 14.37Z"
                fill={color}/>
            <path
                d="M22.315 14.37C21.6045 14.37 20.9099 14.5807 20.3192 14.9755C19.7284 15.3702 19.2679 15.9313 18.996 16.5878C18.7241 17.2442 18.6529 17.9665 18.7916 18.6634C18.9302 19.3603 19.2723 20.0004 19.7748 20.5028C20.2772 21.0052 20.9173 21.3474 21.6142 21.486C22.3111 21.6246 23.0334 21.5535 23.6898 21.2816C24.3463 21.0097 24.9073 20.5492 25.3021 19.9584C25.6968 19.3676 25.9075 18.6731 25.9075 17.9625C25.9122 17.4895 25.8225 17.0202 25.6436 16.5823C25.4647 16.1443 25.2002 15.7464 24.8657 15.4119C24.5312 15.0774 24.1333 14.8129 23.6953 14.634C23.2574 14.4551 22.7881 14.3654 22.315 14.37Z"
                fill={color}/>
            <path
                d="M32.618 53.8875L29.5 52.0913L36.685 39.5175H47.4625C47.9345 39.5183 48.4021 39.426 48.8383 39.2457C49.2745 39.0654 49.6709 38.8009 50.0046 38.4671C50.3384 38.1334 50.603 37.737 50.7832 37.3008C50.9635 36.8645 51.0558 36.397 51.055 35.925V10.7775C51.0558 10.3055 50.9635 9.83801 50.7832 9.40179C50.603 8.96556 50.3384 8.56921 50.0046 8.23546C49.6709 7.9017 49.2745 7.63711 48.8383 7.45685C48.4021 7.2766 47.9345 7.18423 47.4625 7.18503H11.5375C11.0655 7.18423 10.598 7.2766 10.1618 7.45685C9.72557 7.63711 9.32922 7.9017 8.99546 8.23546C8.66171 8.56921 8.39712 8.96556 8.21686 9.40179C8.03661 9.83801 7.94424 10.3055 7.94504 10.7775V35.925C7.94424 36.397 8.03661 36.8645 8.21686 37.3008C8.39712 37.737 8.66171 38.1334 8.99546 38.4671C9.32922 38.8009 9.72557 39.0654 10.1618 39.2457C10.598 39.426 11.0655 39.5183 11.5375 39.5175H27.7038V43.11H11.5375C10.5939 43.1102 9.65956 42.9245 8.78776 42.5634C7.91597 42.2024 7.12383 41.6732 6.45661 41.006C5.78939 40.3387 5.26015 39.5466 4.89913 38.6748C4.53811 37.803 4.35237 36.8686 4.35254 35.925V10.7775C4.35226 9.8339 4.53791 8.89947 4.89889 8.02761C5.25987 7.15576 5.7891 6.36358 6.45635 5.69634C7.12359 5.02909 7.91577 4.49986 8.78762 4.13888C9.65948 3.7779 10.5939 3.59225 11.5375 3.59253H47.4625C48.4062 3.59225 49.3406 3.7779 50.2125 4.13888C51.0843 4.49986 51.8765 5.02909 52.5437 5.69634C53.211 6.36358 53.7402 7.15576 54.1012 8.02761C54.4622 8.89947 54.6478 9.8339 54.6475 10.7775V35.925C54.6477 36.8686 54.462 37.803 54.1009 38.6748C53.7399 39.5466 53.2107 40.3387 52.5435 41.006C51.8762 41.6732 51.0841 42.2024 50.2123 42.5634C49.3405 42.9245 48.4061 43.1102 47.4625 43.11H38.777L32.618 53.8875Z"
                fill={color}/>
        </svg>


    );
}
export default sentimentalAnalysis;