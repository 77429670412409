import {useLogto} from "@logto/react";
import {authStore} from "../store/authStore.tsx";
import {useEffect} from "react";
import { useGetTokenOrganization } from "./useGetTokenOrganization.tsx";
export const currentSite = 'Assist';

export const useGetAccessToken = () => {
    const { isAuthenticated, getAccessToken} = useLogto();
    const { accessToken, setAccessToken } = authStore.getState();
    const { fetchUpdatedToken } = useGetTokenOrganization();
    
    useEffect(() => {
        const fetchToken = async () => {
            if (isAuthenticated) {
                const token = await fetchUpdatedToken();
                setAccessToken(token || '');
            }
        };

        fetchToken();

    }, [accessToken, getAccessToken, isAuthenticated, setAccessToken, fetchUpdatedToken]);
    return accessToken
}