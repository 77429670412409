const providersListValue = [
    { name: "Azure", value: "azure" },
    { name: "Google", value: "google" },
    { name: "AWS", value: "aws" },
];
const results = [
    { provider: "ChatGPT", sentiment: "NEGATIVE", time: "0.75 s" },
    { provider: "Google", sentiment: "POSITIVE", time: "0.22 s" },
    { provider: "Azure", sentiment: "NEUTRAL", time: "0.37 s" },
    { provider: "AWS", sentiment: "POSITIVE", time: "0.21 s" },
];
export {results, providersListValue}
