import React, {ReactNode} from 'react';
import './CustomForm.css';
import { Stack} from "@chakra-ui/react";
import { useGetTokenOrganization } from '../../../hooks/useGetTokenOrganization';


interface Props {
    actionUrl: string; // url to sent the form data
    method: 'POST' | 'GET';
    onSubmitSuccess?: (response: { audio: string; stats: { processing_time_ms: number } }) => void; // function  to execute if submit is succes
    onSubmitError?: (error:string) => void;
    children: ReactNode;
    formData: { [key: string]: string } // format data to be sent in the answer
}

const CustomForm: React.FC<Props> = ({actionUrl, method, onSubmitSuccess, onSubmitError, children, formData}) =>{
    const { fetchUpdatedToken } = useGetTokenOrganization();

    const handleSubmit = async (e: React.FormEvent)=>{
        // prevent the default event by form (page reload)
        e.preventDefault();

        // URL for the API
        const url = `${import.meta.env.VITE_API_ENDPOINT}${actionUrl}`;

        try {
            const token = await fetchUpdatedToken();
            const response = await fetch(url, {
                method,
                headers:{
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData),
            });
            // if response is not successful
            if (!response.ok) {
                throw new Error(`Failed to obtain access token.`);
            }
            //
            // Parse response as JSON
            const data = await response.json();

            // execute the function and received the data
            if(onSubmitSuccess) {
                onSubmitSuccess(data);
            }
        }catch(error){
            console.error('Error to send: ', error);
            if(onSubmitError){
                onSubmitError(error as string);
            }
        }
    }

    return (
        <form onSubmit={handleSubmit} className={'wrapper-custom-form'}>
            <Stack>
                {children}
            </Stack>
        </form>
    );
}

export default CustomForm;