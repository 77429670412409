import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import {Text, Flex, Spinner, Button, useToast} from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";
import { useTheme } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface AudioUploadProps {
    onAudioUpload: (file: File) => void;
}

const AudioUpload: React.FC<AudioUploadProps> = ({ onAudioUpload }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const MAX_FILE_SIZE_MB = 200;
    const SUPPORTED_FORMATS = ["audio/wav", "audio/x-wav","audio/mp3", "audio/mpeg", "audio/ogg", "video/ogg"];
    // Convert SUPPORTED_FORMATS to an object for dropzone accept
    const acceptObject = SUPPORTED_FORMATS.reduce((acc, format) => {
        acc[format] = []; // each MIME type gets an empty array
        return acc;
    }, {} as { [key: string]: [] });

    const handleFileUpload = (files: File[]) => {
        const file = files[0];

        if (file) {
            if (!SUPPORTED_FORMATS.includes(file.type)) {
                toast({
                    title: t("Invalid file format"),
                    description: t("Please upload a WAV file."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                });
                return;
            }

            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                toast({
                    title: t("File size exceeds limit"),
                    description: `${t("File size exceeds")} ${MAX_FILE_SIZE_MB}MB.`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                });
                return;
            }

            setIsLoading(true);
            setTimeout(() => {
                onAudioUpload(file);
                setIsLoading(false);
                toast({
                    title: t("File uploaded successfully"),
                    description: t("Your audio file has been uploaded."),
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                    position: "top-right"
                });
            }, 1000);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleFileUpload,
        multiple: false,
        accept: acceptObject,
        onDropRejected: () => {
            toast({
                title: t("Invalid file format"),
                description: t("Please upload a valid audio file (WAV, MP3, OGG)."),
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
            });
        },
    });

    return (
        <Flex
            {...getRootProps()}
            width="100%"
            border="2px dashed"
            borderColor={isDragActive ? "white.50" : "blue.50"}
            borderRadius="md"
            direction="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            mb={4}
            textAlign="center"
            _hover={{ borderColor: "white.50" }}
            cursor="pointer"
        >
            <input {...getInputProps()} aria-label="Upload audio file" />
            {isLoading ? (
                <>
                    <Spinner size="lg" color="white" />
                    <Text mt={4} fontSize="md" color="white">
                        {t("Uploading...")}
                    </Text>
                </>
            ) : (
                <>
                    <FiUpload size={32} color={theme.colors.blue[50]} />
                    {isDragActive ? (
                        <Text mt={2} fontSize="md" color="white">
                            {t("Drop the file here...")}
                        </Text>
                    ) : (
                        <>
                            <Text mt={2} fontSize="md">
                                {t("Drag and drop here (limit 200MB per file - WAV)")}
                            </Text>
                            <Text mt={1} fontSize="md" color="blue.50">
                                {t("or")}
                            </Text>
                            <Button variant="link" color="blue.50" mt={1}>
                                {t("Browse files")}
                            </Button>
                        </>
                    )}
                </>
            )}
        </Flex>
    );
};

export default AudioUpload;
