import { useLogto } from "@logto/react";
import { useEffect, useState } from "react";
import { useGetTokenOrganization } from "./useGetTokenOrganization";

export const useCheckTokenExpiration = () => {
    const { isAuthenticated } = useLogto();
    const [timeUntilExpiration, setTimeUntilExpiration] = useState<number | null>(null); // Tiempo restante en segundos
    const [isTokenValid, setIsTokenValid] = useState<boolean>(true); // Estado de validez del token
    const { fetchUpdatedToken } = useGetTokenOrganization();
    
    useEffect(() => {
        const checkExpiration = async () => {
            if (!isAuthenticated) {
                setTimeUntilExpiration(null);
                setIsTokenValid(true); // Si no está autenticado, el token es considerado válido
                return;
            }

            try {
                const token = await fetchUpdatedToken();
                if (!token) {
                    setTimeUntilExpiration(null);
                    setIsTokenValid(false); // Si no hay token, se considera no válido
                    return;
                }

                const tokenParts = token.split(".");
                if (tokenParts.length !== 3) {
                    setTimeUntilExpiration(null);
                    setIsTokenValid(false); // Si el formato del token es incorrecto
                    return;
                }

                const payload = JSON.parse(atob(tokenParts[1]));
                const exp = payload.exp * 1000; // Convertir a milisegundos
                const currentTime = Date.now();

                const remainingTime = exp > currentTime ? Math.ceil((exp - currentTime) / 1000) : null;
                setTimeUntilExpiration(remainingTime);
                setIsTokenValid(exp > currentTime); // Solo es válido si no ha expirado
            } catch (error) {
                console.error("Error al decodificar el token:", error);
                setTimeUntilExpiration(null);
                setIsTokenValid(false); // Si ocurre un error, se considera no válido
            }
        };

        checkExpiration().then(); // Llama a la función inicialmente

        const interval = setInterval(checkExpiration, 10000); // Comprobar cada 10 segundos

        return () => clearInterval(interval); // Limpia el intervalo cuando el componente se desmonta
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return { timeUntilExpiration, isTokenValid }; // Devuelve el tiempo restante y la validez del token
};
