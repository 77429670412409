import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const Ear : React.FC<Props> = ({color='#183b4d', height=24, width=24}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.5 22.5V21C15.4884 20.9977 17.3948 20.2068 18.8008 18.8008C20.2068 17.3948 20.9977 15.4884 21 13.5H22.5C22.4973 15.8861 21.5483 18.1738 19.861 19.861C18.1738 21.5483 15.8861 22.4973 13.5 22.5Z"
                fill={color}/>
            <path
                d="M13.5 19.5V18C14.6931 17.9987 15.8369 17.5242 16.6805 16.6805C17.5242 15.8369 17.9987 14.6931 18 13.5H19.5C19.4982 15.0907 18.8654 16.6158 17.7406 17.7406C16.6158 18.8654 15.0907 19.4982 13.5 19.5Z"
                fill={color}/>
            <path
                d="M13.5 16.5V15C13.8977 14.9995 14.279 14.8414 14.5602 14.5602C14.8414 14.279 14.9995 13.8977 15 13.5H16.5C16.4992 14.2954 16.1828 15.058 15.6204 15.6204C15.058 16.1828 14.2954 16.4992 13.5 16.5Z"
                fill={color}/>
            <path
                d="M7.5 1.5C5.7104 1.50199 3.99466 2.21378 2.72922 3.47922C1.46378 4.74466 0.751985 6.4604 0.75 8.25H2.25C2.25 6.85761 2.80312 5.52226 3.78769 4.53769C4.77225 3.55312 6.10761 3 7.5 3C8.89239 3 10.2277 3.55312 11.2123 4.53769C12.1969 5.52226 12.75 6.85761 12.75 8.25C12.7527 9.17952 12.5116 10.0935 12.0509 10.9008C11.5902 11.7081 10.9259 12.3806 10.1242 12.8511L9.75 13.0672V15.3721C9.75361 15.6729 9.69551 15.9713 9.5793 16.2488C9.46309 16.5264 9.29123 16.7771 9.07432 16.9856C8.6417 17.4511 8.07789 17.7742 7.45755 17.9122C6.83721 18.0501 6.1896 17.9964 5.60047 17.7581C5.0599 17.5244 4.59798 17.1401 4.26986 16.651C3.94174 16.162 3.76127 15.5888 3.75 15H2.25C2.26349 15.8805 2.53017 16.7385 3.01818 17.4716C3.50619 18.2046 4.1949 18.7816 5.00205 19.1338C5.54937 19.3737 6.14035 19.4978 6.73792 19.4985C7.99714 19.472 9.19867 18.9655 10.0968 18.0825C10.4641 17.7336 10.756 17.313 10.9543 16.8468C11.1527 16.3806 11.2533 15.8787 11.25 15.3721V13.9153C12.1754 13.2885 12.9328 12.4442 13.4559 11.4564C13.9789 10.4687 14.2516 9.36768 14.25 8.25C14.248 6.4604 13.5362 4.74466 12.2708 3.47922C11.0053 2.21378 9.2896 1.50199 7.5 1.5Z"
                fill={color}/>
            <path
                d="M6.96002 6.06191C7.29172 5.98082 7.6375 5.97613 7.97128 6.0482C8.30505 6.12027 8.6181 6.26721 8.88679 6.47793C9.15548 6.68866 9.37279 6.95767 9.52233 7.26465C9.67187 7.57163 9.74972 7.90857 9.75002 8.25004H11.25C11.2512 7.68612 11.1247 7.12925 10.88 6.62122C10.6352 6.11319 10.2786 5.6672 9.83679 5.31671C9.39026 4.963 8.86958 4.71476 8.31365 4.59053C7.75771 4.46629 7.18089 4.46928 6.62627 4.59926C5.95004 4.75621 5.33136 5.09962 4.84048 5.5905C4.3496 6.08138 4.00619 6.70006 3.84924 7.37629C3.66712 8.1537 3.73626 8.96885 4.04672 9.70448C4.35719 10.4401 4.89297 11.0583 5.57702 11.4702C5.9275 11.6681 6.22015 11.9542 6.42591 12.3001C6.63167 12.646 6.74341 13.0398 6.75002 13.4422V15H8.25002V13.4422C8.24466 12.7802 8.06623 12.131 7.73243 11.5593C7.39863 10.9875 6.92108 10.513 6.34719 10.1829C5.96323 9.95454 5.65588 9.61714 5.46426 9.21361C5.27263 8.81008 5.2054 8.35866 5.2711 7.9168C5.33681 7.47494 5.5325 7.06262 5.83325 6.73231C6.13401 6.402 6.52623 6.16863 6.96002 6.06191Z"
                fill={color}/>
        </svg>
    );
}

export default Ear;