import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    es: {
        translation: {
            "Try the different Generative AI that your product can work with.": "Prueba las diferentes IA Generativas con las que tu producto puede funcionar.",
            "Choose an option": "Elige una opción.",
            "Select provider": "Seleccionar proveedor",
            "You are a helpful assistant": "Eres un asistente útil.",
            "AI Guidelines": "Texto a analizar",
            "Continue" : "Continuar",
            "Generative AI": "IA Generativa",
            "Brain": "Cerebro",
            "< back": "< volver",
            "Sentimental analysis": "Analisis sentimental",
            "Identify the sentiment of the speakers on a conversation or message.":"Identifica el sentimiento de los interlocutores en una conversación o mensaje.",
            "Choose a language": "Seleccionar lenguaje",
            "Spanish": "Español",
            "English": "Inglés",
            "Analyze": "Analizar",
            "There was an error in the request.": "Hubo un error en la petición",
            "Failed to obtain access token.": "Error al obtener el token de acceso.",
            "Select provider/s": "Selecciona un proveedor/es",
            "Error accessing microphone:": "Error al acceder al micrófono:",
            "There was an error with the audio.": "Hubo un error con el audio.",
            "Mouth": "Boca",
            "Voice cloning": "Clonación de voz",
            "Providers": "Proveedores",
            "Upload or Record Audio": "Subir o Grabar Audio",
            "Write your text...": "Escribe tu texto...",
            "Text to convert to audio": "Texto para convertir a audio",
            "or": "o",
            "Data is empty": "La información esta vacia.",
            "There was an error please try again": "Hubo un error porfavor, pruebelo devuelta.",
            'Failed to generate image.': "Fallo al generar la imagen.",
            "Success": "Éxito",
            "The image was created successfully": "La imagen se creo exitosamente",
            "Image not found in response": "Imagen no encontrada en la respuesta",
            "Error generating image:": "Error al generar la imagen",
            "An error occurred while generating the image.": "Ha ocurrido un error mientras se generaba la imagen",
            "Eyes": "Ojos",
            "Image generation": "Generación de imagen",
            "Generate": "Generar",
            "Generated Image:": "Generar imagen",
            "Download Image": "Descargar imagen",
            "System Prompt":"Indicador del Sistema",
            "The file exceeds the maximum size of 200MB.": "El archivo excede el tamaño máximo de 200MB.",
            "Image interaction": "Interacción de imagen",
            "Upload an image and ask whatever you want to the AI.": "Sube una imagen y pregunta lo que quieras a la IA.",
            "Drag and drop here (limit 200MB per file - JPG, JPEG, PNG)": "Arrastra y suelta aquí (límite de 200MB por archivo - JPG, JPEG, PNG)",
            "Browse files": "Examinar archivos",
            "Ask IA": "Preguntar a la IA",
            "Audio missing.": "Falta el audio",
            "Please upload or record an audio file to transcribe.": "Por favor, sube o graba un archivo de audio para transcribir.",
            "Error getting Token.": "Error al obtener el token.",
            "Please try again": "Porfavor pruebelo devuelta.",
            "No Transcription Found.":"No se encontró transcripción.",
            "No transcription was available for the provided audio.":"No hubo transcripción disponible para el audio proporcionado.",
            "Error processing audio:": "Error procesando el audio",
            "An error occurred while processing the audio.": "Ha ocurrido un error al procesar el audio.",
            "Copied to clipboard!": "¡Copiado al portapapeles!",
            "The transcription has been copied.": "La transcripción fue copiada.",
            "Failed to copy.": "Error al copiar.",
            "An error occurred while copying to clipboard.":"Ocurrió un error al copiar al portapapeles.",
            "Ear": "Oído",
            "VOICE UNDERSTANDING": "COMPRENSIÓN DE VOZ",
            "See how our diverse audio transcriber works.":"Descubre cómo funcionan nuestros transcriptores de audio.",
            "Select language": "Seleccionar el idioma",
            "Transcribe your audio": "Transcribe tu audio",
            "Your audio file": "Tu archivo de audio.",
            'No transcription available': "No hay una transcripción disponible.",
            "Copy": "Copiar",
            "Reset All": "Limpiar todo",
            "Invalid file format": "Formato inválido",
            "Please upload a WAV file.": "",
            "File size exceeds limit": "El archivo supero el tamaño máximo.",
            "File size exceeds": "Archivo excedido de tamaño",
            "File uploaded successfully": "Archivo subido con éxito.",
            "Your audio file has been uploaded.": "Tu archivo de audio ha sido subido.",
            "Uploading...": "Cargando...",
            "Drop the file here...": "Suelta el archivo aquí...",
            "Drag and drop here (limit 200MB per file - WAV)": "Arrastra y suelta aquí (límite de 200MB por archivo - WAV)",
            "Recording...": "Grabando...",
            "Record your voice": "Grabando tu voz",
            "Pause Audio" : "Pausar audio",
            "Play Audio": "Reproducir audio",
            "Playing...": "Reproduciendo...",
            "Your VOICE AI": "Tu VOZ IA",
            "Download": "Descargar",
            "Is this audio okay?": "Tu audio esta bien?",
            "How can I help you?": "¿Cómo puedo ayudarte?",
            "Send message": "Enviar mensaje",
            "Your message...": "Tu mensaje...",
            'Test different AI models by interacting with them directly.': "Prueba diferentes modelos de IA interactuando con ellos directamente.",
            'Identify the emotional tone of the conversation.': "Identifica el tono emocional de la conversación.",
            'Compare various voice-to-text transcription options to find the best fit.': "Compara diversas opciones de transcripción de voz a texto para encontrar la que mejor se adapte.",
            "Explore and compare different AI-generated voices for your needs.": "Explora y compara diferentes voces generadas por IA para tus necesidades.",
            "Eye": "Ojo",
            "Sentiment result": "Resultado sentimental",
            'Home': "Inicio",
            'Subscription': "Subscripción",
            'Sentiment Analysis': "Análisis Sentimental",
            'Voice Clonning': "Clonación de Voz",
            'Voice Understanding': "Comprensión de Voz",
            'AI Voices': "Voces de IA",
            "Voice Biometry": "Biometría de Voz",
            "What kind of AI would you like to create?": "¿Qué tipo de IA te gustaría crear?",
            "Describe something and let the Generative AI to generate the image.":"Describe algo y deja que la IA Generativa genere la imagen.",
            "Make your conversational AI  speaks like whoever you want.":"Haz que tu IA conversacional hable como quien tú quieras.",
            "Cost of generative AI":"Coste de la IA generativa",
            "Text to convert to speech" : "Conversión de texto a voz",
            "Select voice" : "Selecciona la voz",
            "Choose a provider" : "Elija un proveedor",
            "Choose a voice" : "Elige una voz",
            "Clone a voice and test it by having it speak your inputted text." : "Clona una voz y pruébala haciendo que pronuncie el texto que has introducido.",
            "Select the voice that suit your company or product.":"Elija la voz que mejor se adapte a su empresa o producto",
            "Type your text...":"Escriba su texto...",
            "Describe something and let the Generative AI generate the image.":"Describe algo y deja que la IA Generativa genere la imagen.",
            "Instructions of the image to generate.":"Instrucciones de la imagen a generar.",
            "Upload Another Image":"Cargar otra imagen",
            "View Image":"Ver imagen",
            "Settings":"Configuraciones",
            "Logout": "Cerrar sesión",
            "Analyze visual content using AI-powered language models.": "Analice contenidos visuales utilizando modelos lingüísticos basados en IA.",
            "Transform text prompts into high-quality images using AI-powered language models.":"Transforme mensajes de texto en imágenes de alta calidad utilizando modelos lingüísticos basados en IA.",
            "Are you sure you want to upload a new image? This will reset the chat": "¿Estás seguro de que quieres subir una nueva imagen? Esto reiniciará el chat",
            "What's in this image?":"¿Qué hay en esta imagen?",
            "Initial Request Failed":"Solicitud inicial fallida",
            "Unable to analyze the image. Please try again.":"No se ha podido analizar la imagen. Por favor, inténtelo de nuevo.",
            "Connection Error":"Error de conexión",
            "Failed to process your request. Please check your connection and try again.":"No se ha podido procesar su solicitud. Compruebe su conexión e inténtelo de nuevo.",
            "Image is empty":"La imagen está vacía",
            "The Assistant is typing...":"El asistente está escribiendo...",
            "Ask whatever you want":"Pregunta lo que quieras",
            "Please upload a valid audio file (WAV, MP3, OGG).":"Cargue un archivo de audio válido (WAV, MP3, OGG).",
            "NO ACTIVE SUBSCRIPTIONS":"NO HAY SUSCRIPCIONES ACTIVAS",
            "No active subscriptions. Please contact your administrator for more information.": "No hay suscripciones activas. Por favor, contacte con su administrador para más información.",
            "Renew your payment to use your products.":"Renueva tu pago para usar tus productos.",
        }
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'es', // Initial language
    fallbackLng: 'en', // Language in case there is no translation
    interpolation: {
        escapeValue: false, // react-i18next takes care of security
    }
});



export default i18n;
