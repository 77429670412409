import { useState, useRef } from "react";

export const useAudioHandler = () => {
    const [audioSrc, setAudioSrc] = useState<string | null>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;
            audioChunksRef.current = [];
            mediaRecorder.start();
            setIsRecording(true);

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                const blob = new Blob(audioChunksRef.current, { type: "audio/wav" });
                const audioURL = URL.createObjectURL(blob);
                setAudioBlob(blob);
                setAudioSrc(audioURL);
            };
        } catch (error) {
            console.error("Error accessing microphone:", error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            const tracks = mediaRecorderRef.current.stream.getTracks();
            tracks.forEach((track) => track.stop());
        }
    };

    const handleAudioUpload = (uploadedFile: File) => {
        const uploadedAudioUrl = URL.createObjectURL(uploadedFile);
        setAudioSrc(uploadedAudioUrl);
        setAudioBlob(uploadedFile);
    };

    return { audioSrc, isRecording, startRecording, stopRecording, handleAudioUpload, audioBlob };
};
