import {Box, Flex, Stack, Text, useToast} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {useState} from "react";

import MainTitle from "../../../../ui/Components/MainTitle";
import Subtitle from "../../../../ui/Components/SubtitleSeccion";
import ImageInteractionIcon from "../../../../icons/eyes/imageinteraction";
import SelectInput from "../../../../ui/Components/SelectInput";
import RecordingAudio from "../../../../ui/Components/Audio/RecordingAudio";
import AudioUpload from "../../../../ui/Components/Audio/AudioUpload";
import UploadedAudioPlayer from "../../../../ui/Components/Audio/UploadedAudioPlayer";
import ActionButton from "../../../../ui/Components/Button";
import ProvidersCheckbox from "../../../../ui/Components/ProvidersCheckBox";

import {useAudioHandler} from "../../../../hooks/useAudioHandler";
import blobToBase64 from "../../../../utils/blobTobase64";
import {ApiResponseItem, understandingVoice} from "../../../../api/ear/understandingVoice.tsx";

import {FormValues} from "./interfaces";
import {getDataModel} from "./getDataModel.tsx";
import { useTranslation } from "react-i18next";
import { useGetTokenOrganization } from "../../../../hooks/useGetTokenOrganization.tsx";

const transcriberList = [
    {name: "Azure", value: "azure"},
    {name: "Google", value: "google"},
    {name: "Deepgram", value: "deepgram"},
    {name: "OpenAI Whisper", value: "whisper"},
];

export const VoiceUnderstanding = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {handleSubmit, control, watch} = useForm<FormValues>();
    const {isRecording, audioSrc, startRecording, stopRecording, handleAudioUpload} = useAudioHandler();
    const [isPlaying, setIsPlaying] = useState(false);
    const [results, setResults] = useState<ApiResponseItem[]>([]);
    const [isTranscribing, setIsTranscribing] = useState(false);
    const { fetchUpdatedToken } = useGetTokenOrganization();

    const toast = useToast();

    const language = watch("language");
    const model = watch("model");
    const isAudioReady = !!audioSrc;

    const isFormValid = language && model.length > 0 && isAudioReady;

    const onSubmit = async (data: FormValues) => {
        setIsTranscribing(true); 
        try {

            if (!audioSrc) {
                toast({
                    title: t("Audio missing."),
                    description: t("Please upload or record an audio file to transcribe."),
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            const resp = getDataModel(data);
            const audioResponse = await fetch(audioSrc);
            const audioBlob = await audioResponse.blob();
            const audioBase64 = await blobToBase64(audioBlob);

            const token = await fetchUpdatedToken();
            if (!token) {
                toast({
                    title: t("Error getting Token."),
                    description: t("Please try again"),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                throw new Error(t("Failed to obtain access token."));
            }

            const promises = resp.model.map(async (model) => {
                return understandingVoice(
                    {
                        model: model.value,
                        audio_file: audioBase64.split(",")[1],
                    },
                    token
                );
            });

            const results = await Promise.all(promises);
            if (results.length === 0) {
                toast({
                    title: t("No Transcription Found."),
                    description: t("No transcription was available for the provided audio."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
            setResults(results);

        } catch (error) {
            console.error(t("Error processing audio:"), error);
            toast({
                title: "Error.",
                description: t("An error occurred while processing the audio."),
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsTranscribing(false);
        }
    };

    const handlePlayPause = (playing: boolean) => {
        setIsPlaying(playing);
    };

    const backNavigate = () => {
        navigate("/");
    };

    const handleCopy = async (transcription: string) => {
        if (transcription) {
            try {
                await navigator.clipboard.writeText(transcription);
                toast({
                    title: t("Copied to clipboard!"),
                    description: t("The transcription has been copied."),
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } catch (error) {
                toast({
                    title: t("Failed to copy."),
                    description: t("An error occurred while copying to clipboard."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    const resetAll = () => {
        setResults([]);
        setIsPlaying(false);
    };

    return (
        <Flex flexDir="column" className={'voice-understanding'}>
            <Text
                fontFamily="Lato-regular"
                fontSize="18px"
                as="a"
                color="white.50"
                onClick={backNavigate}
                className="back-button"
            >
                {t("< back")}
            </Text>

            <Box
                maxWidth="670px"
                minHeight="100vh"
                w="670px"
                mx="auto"
                py="50px"
                px="15px"
                borderRadius="md"
                position="relative"
                color="white"
            >
                <Stack alignItems="flex-start" height={"90%"}>
                    <Flex mb="46" alignItems="center">
                        <Flex mr="52px">
                            <MainTitle text={t("Ear")}/>
                        </Flex>
                        <Flex alignItems="center">
                            <Subtitle
                                text={t("VOICE UNDERSTANDING")}
                                icon={<ImageInteractionIcon width={24} height={24} color="#FFF"/>}
                            />
                        </Flex>
                    </Flex>
                </Stack>

                <Text fontWeight="bold" fontSize="20px" mb="33px" textAlign="left">
                    {t("See how our diverse audio transcriber works.")}
                </Text>
                {!results.length && (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{width: "100%", display: "flex", flexDirection: "column"}}>

                        <Controller
                            name="language"
                            control={control}
                            render={({field}) => (
                                <SelectInput
                                    placeholder={t("Choose a language")}
                                    options={[
                                        {name: "Español", value: "español"},
                                        {name: "Inglés", value: "inglés"},
                                    ]}
                                    onChange={field.onChange}
                                    label={t("Select language")}
                                    disabled={isTranscribing}
                                />
                            )}
                        />

                        <Controller
                            name="model"
                            control={control}
                            defaultValue={[]}
                            render={({field}) => (
                                <ProvidersCheckbox
                                    providers={transcriberList}
                                    value={field.value}
                                    onChange={(selectedValues) => field.onChange(selectedValues)}
                                />
                            )}
                        />

                        <Box
                            w="100%"
                            mx="auto"
                            py="30px"
                            borderRadius="md"
                            borderColor="white"
                            border="solid 1px"
                            color="white"
                            px="50px"
                            mb="20px"
                        >
                            <Text fontSize="l" fontWeight="normal" mb={4} mt="15px">
                                {t("Upload or Record Audio")}
                            </Text>

                            <Flex direction="column" alignItems="center" mb={4}>
                                <RecordingAudio
                                    isRecording={isRecording}
                                    startRecording={startRecording}
                                    stopRecording={stopRecording}/>
                            </Flex>

                            <Text fontSize="xl" fontWeight="bold" mb={4}>
                                {t('or')}
                            </Text>

                            <Flex
                                direction="column"
                                alignItems="center"
                                mb={4}
                                opacity={isRecording ? 0.5 : 1}
                                pointerEvents={isRecording ? "none" : "auto"}
                                transition="all 0.3s ease"
                            >
                                <AudioUpload onAudioUpload={handleAudioUpload}/>
                            </Flex>

                            {audioSrc && (
                                <Flex direction="column" alignItems="center" mt={4}>
                                    <UploadedAudioPlayer
                                        audioSrc={audioSrc}
                                        isPlaying={isPlaying}
                                        onPlayPause={handlePlayPause}/>
                                </Flex>
                            )}
                        </Box>

                        <ActionButton
                            text={t("Transcribe your audio")}
                            isDisabled={!isFormValid}
                            isLoading={isTranscribing}
                            onClick={handleSubmit(onSubmit)}
                        />
                    </form>
                )}

                {results.length > 0 && (
                    <Box mt={6} textAlign="left">
                        <Text fontSize={"20px"} fontWeight="bold" mb={6}>{t("Your audio file")}</Text>
                        {audioSrc && (
                            <Flex direction="column" alignItems="left" mt={4} mb={6}>
                                <UploadedAudioPlayer
                                    audioSrc={audioSrc}
                                    isPlaying={isPlaying}
                                    onPlayPause={handlePlayPause}/>
                            </Flex>
                        )}
                        {results.map((result, index) => (
                            <Box key={index} mb={6}>
                                <Text
                                    fontSize={"20px"}
                                    mb={2}
                                    style={{textTransform: 'uppercase'}}
                                    fontWeight="semibold">
                                    {`${result.name.split(":")[0]}`}
                                </Text>
                                <Text
                                    mb={4}
                                    fontSize={"20px"}>
                                    {`Transcription: ${result.response.transcription || t('No transcription available')}`}
                                </Text>

                                {result.response.transcription && (
                                    <ActionButton
                                        text={t("Copy")}
                                        onClick={() => handleCopy(result.response.transcription)}
                                    />
                                )}
                            </Box>
                        ))}

                        <ActionButton
                            text={t("Reset All")}
                            onClick={resetAll}
                        />

                    </Box>
                )}

            </Box>
        </Flex>
    );
};
