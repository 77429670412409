//
//
//  Pricing
//
//
 
import {useLogto} from "@logto/react"
import {useEffect, useState} from "react"
import './pricing.css';
import {Flex, Spinner} from "@chakra-ui/react";
import { useGetTokenOrganization } from "../../hooks/useGetTokenOrganization";
 
 
const LoadPotioScript = () => {
    // We need to load the Potio script in order to use the Potio web component
    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://webcomponent.potio.cc/index.js"
        script.async = true
        document.body.appendChild(script)
 
        return () => {
            document.body.removeChild(script)
        }
    }, [])
 
    return null
}
 
 
function Pricing() {
    const [isLoading, setIsLoading] = useState(true)
    const { getIdTokenClaims } = useLogto()
    const [userId, setUserId] = useState<string | undefined>(undefined)
    const [userEmail, setUserEmail] = useState<string | undefined>(undefined)
    const [stripeCustomer, setStripeCustomer] = useState<string | undefined>(undefined)
    const [clientReferenceId, setClientReferenceId] = useState<string | undefined>(undefined)
    const { fetchUpdatedToken } = useGetTokenOrganization();
 
    useEffect(() => {
        const getStripeCustomerPromise = fetchUpdatedToken()
            .then(token => {
                return fetch(import.meta.env.VITE_API_ENDPOINT + "/api/user/pricing", {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            }).then(response => response.json()).then(data => {
                setStripeCustomer(data["customer_id"])
                setClientReferenceId(data["client_reference_id"])
            })
 
        const getUserInformation = getIdTokenClaims()
            .then(claims => {
                setUserId(claims?.sub)
                setUserEmail(claims?.email || undefined)
            })
 
        Promise.all([getStripeCustomerPromise, getUserInformation])
            .finally(() => {
                setIsLoading(false)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ getIdTokenClaims])
 
    if (isLoading) {
        return (
            <Flex height="100vh" alignItems="flex-start" justifyContent="center">
                <Spinner size="xl" thickness="4px" color="blue.500" />
            </Flex>
        );
    }
 
    return (
        <>
            <h2 className={'pricing-title'}>Pricing</h2>
 
            {stripeCustomer ? (
                <potio-pricing-table
                    customer={stripeCustomer}
                    client-reference-id={clientReferenceId}
                    action="payment"
                    data-key={import.meta.env.VITE_POTIO_PRICING_TABLE_KEY}
                ></potio-pricing-table>
            ) : (
                <potio-pricing-table
                    customer-email={userEmail}
                    client-reference-id={userId}
                    action="payment"
                    data-key={import.meta.env.VITE_POTIO_PRICING_TABLE_KEY}
                ></potio-pricing-table>
            )}
                
            <LoadPotioScript />
        </>
    )
}
 
export default Pricing