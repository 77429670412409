import React from "react";
import { Box, Flex, Text, Checkbox } from "@chakra-ui/react";
import {ProvidersCheckboxProps, Provider} from './interfaces';
import { useTranslation } from "react-i18next";

const ProvidersCheckbox: React.FC<ProvidersCheckboxProps> = ({ providers, value = [], onChange }) => {
    const { t } = useTranslation();
    const handleCheckboxChange = (provider: Provider, isChecked: boolean) => {
        const newValues = isChecked
            ? [...value, provider]
            : value.filter((selected) => selected.value !== provider.value);

        onChange && onChange(newValues);
    };

    return (
        <Box textAlign="left" mb="25px">
            <Text fontWeight="bold" mb={2}>{t("Select provider/s")}</Text>
            <Flex wrap="wrap" gap={4}>
                {providers.map((provider) => (
                    <Checkbox
                        key={provider.value}
                        isChecked={value.some((selected) => selected.value === provider.value)} // Chequeo basado en el `value`
                        onChange={(e) => handleCheckboxChange(provider, e.target.checked)}
                        padding={2}
                    >
                        {provider.name}
                    </Checkbox>
                ))}
            </Flex>
        </Box>
    );
};

export default ProvidersCheckbox;
