import React from 'react';

interface Props {
    audioURL: string;
}

const AudioPlayer: React.FC<Props> = ({ audioURL }) => {
    return (
        <div className="wrapper-audio-player">
            <audio controls style={{ background: '#959595', borderRadius: '1rem' }}  >
                <source src={audioURL}  type="audio/mp3" />
                Your browser does not support the audio element.
            </audio>
        </div>
    );
};

export default AudioPlayer;