import React from 'react';
import './CustomSelect.css';
import {Box, Select} from '@chakra-ui/react';
import { Option} from './interface.tsx';


interface Props {
    options?: Option[];
    value?: string;
    onChange?: (value: string) => void;
    text?: string;
    name?: string;
    textLabel?: string;
}

const CustomSelect: React.FC<Props> = ({options = [],value,onChange,text,name, textLabel})=>{

    return (
        <Box className="custom-select-wrapper">
            <label htmlFor={name} className="custom-select-label">{textLabel}</label>
            <Select
                name={name}
                value={value}
                onChange={(e) => onChange && onChange(e.target.value)}>
                <option className={'first-option'} value={""}>{text}</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
        </Box>
    )
};

export default CustomSelect;
