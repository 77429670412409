import React from "react";
import { Box, Flex, Text, VStack, Divider } from "@chakra-ui/react";
import IconTable from '../../../icons/tableIcons';
import {getSentimentColor} from './utils';
import {SentimentResultsTableProps} from './interfaces';
import { useTranslation } from "react-i18next";

const SentimentResultsTable: React.FC<SentimentResultsTableProps> = ({ results }) => {
    const { t } = useTranslation();
    return (
        <Box width="100%" maxW="500px" p={4} borderRadius="md">
            <Text fontWeight="bold" fontSize="lg" mb={4}>
                {t("Sentiment result")}
            </Text>
            <VStack spacing={4} align="stretch">
                {results.map((result, index) => (
                    <Box key={index}>
                        <Flex align="center" justify="space-between" py={2}>
                            <Flex align="center" width="40%">
                                <Text fontWeight="bold" mr={2} width="80px">
                                    {result.name}
                                </Text>
                             
                            </Flex>
                            <Flex align="center" width="60%">
                                <IconTable color={getSentimentColor(result.text)} />
                                <Text ml={2} fontWeight="medium" color="white.50" textTransform="capitalize">
                                    {result.text.toLowerCase()}
                                </Text>
                            </Flex>
                            { result.time 
                                && <Text fontWeight="medium" width="50px" textAlign="right">
                                    {result.time}ms
                                </Text> }
                      
                        </Flex>
                        {index < results.length - 1 && <Divider />}
                    </Box>
                ))}
            </VStack>
        </Box>
    );
};

export default SentimentResultsTable;
