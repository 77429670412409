import React from 'react';

interface CustomSVGIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const CustomSVGIcon: React.FC<CustomSVGIconProps> = ({
    width = "24px",
    height = "24px",
    color = "#ff192e",
}) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
    >
        <g fill="none">
            <path d="M0 12 l0 -12 12 0 12 0 0 12 0 12 -12 0 -12 0 0 -12z" />
        </g>
        <g fill={color}>
            <path d="M6.6 21.9 c-1.4 -1.1 -2.6 -2.9 -2.6 -3.9 0 -1.6 0.5 -1.4 2.9 1.1 1.6 1.6 3.9 2.9 5 2.9 2.3 0 7.3 -4.3 8.6 -7.5 0.8 -2 0.8 -2 1.3 0 0.2 1 -0.1 2.1 -0.7 2.3 -0.6 0.2 -1.1 1 -1.1 1.7 0 2.1 -5 5.5 -8 5.5 -1.5 0 -3.9 -0.9 -5.4 -2.1z" />
            <path d="M1.6 16.4 c-0.7 -1.9 0.2 -3.7 1.5 -2.9 0.5 0.3 0.6 -0.1 0.3 -0.9 -0.7 -1.8 3.8 -6.6 6.1 -6.6 2.2 0 1.9 1.8 -0.5 2.5 -1.1 0.4 -3 2.6 -4.1 5.1 -1.2 2.4 -2.2 4.4 -2.4 4.4 -0.2 0 -0.6 -0.7 -0.9 -1.6z" />
            <path d="M7.2 13.9 c0.2 -0.6 0.8 -1 1.3 -1 0.5 0 1.1 0.4 1.3 1 0.2 0.6 -0.4 1.1 -1.3 1.1 -0.9 0 -1.5 -0.5 -1.3 -1.1z" />
            <path d="M13 14.1 c0 -1.7 1.8 -2.1 2.4 -0.6 0.3 0.9 0 1.5 -0.9 1.5 -0.8 0 -1.5 -0.4 -1.5 -0.9z" />
            <path d="M16.2 9.8 c-3.8 -3.3 -3.6 -4.9 0.3 -2.8 2.2 1.2 4.3 4.6 3.3 5.5 -0.1 0.2 -1.8 -1 -3.6 -2.7z" />
            <path d="M10.7 2.4 c-0.8 -0.8 0.1 -2.4 1.4 -2.4 0.5 0 0.9 0.7 0.9 1.5 0 1.5 -1.2 2 -2.3 0.9z" />
        </g>
    </svg>
);

export default CustomSVGIcon;
