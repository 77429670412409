interface SentimentAnalysisResponse {
      sentiment: string;
      stats: {
        processing_time_ms: number;
      };
  }
  

export async function sentimentalAnalysisApi(
    inputObject: { model: { name: string; value: string }[]; text: string },
    token: string,
): Promise<{ name: string; text: string; time: number }[]> {
    const { model, text } = inputObject;
  
    const results: { name: string; text: string, time: number }[] = [];
  
    for (const modelItem of model) {
        const { name, value } = modelItem;
  
        const requestBody = {
            model: value,
            text: text,
        };
  
        try {
            const response = await fetch(
                `${import.meta.env.VITE_API_ENDPOINT}/api/experiments/sentiment-analysis`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json;charset=UTF-8',
                        'Access-Control-Allow-Origin': '*',
                    },
                    body: JSON.stringify(requestBody),
                }
            );
            if (!response.ok) {
                throw new Error(`Call error ${name}`);
            }
  
            const resData: SentimentAnalysisResponse = await response.json();
            results.push({
                name: name,
                text: resData.sentiment,
                time: resData.stats.processing_time_ms
            });
        } catch (error) {
            throw new Error(`Call error`);
        }
    }
    return results;
}
  