import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const Mouth: React.FC<Props> = ({color='#183b4d', height=24,width = 24}) =>{
    return (
        <svg width={width} height={height} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4150_5616)">
                <path
                    d="M19.4999 17.9934H17.9999V16.194H14.9999C14.0057 16.1931 13.0525 15.8769 12.3495 15.3147C11.6464 14.7525 11.251 13.9902 11.2499 13.1951V12.4278L9.51276 11.9648C9.40622 11.9364 9.30903 11.8892 9.22834 11.8268C9.14766 11.7643 9.08554 11.6881 9.04658 11.6039C9.00762 11.5197 8.99281 11.4295 9.00324 11.3401C9.01368 11.2507 9.04909 11.1643 9.10686 11.0873L11.2499 8.23072V6.59758C11.2519 5.16643 11.9637 3.79435 13.2291 2.78237C14.4946 1.7704 16.2103 1.20117 17.9999 1.19958H21.7499V2.39914H17.9999C16.608 2.40038 15.2735 2.84311 14.2893 3.6302C13.3051 4.41729 12.7515 5.48447 12.7499 6.59758V8.39691C12.7499 8.50561 12.7129 8.61226 12.643 8.70544L10.874 11.0633L12.2371 11.4266C12.3864 11.4664 12.5163 11.5427 12.6083 11.6449C12.7004 11.747 12.7499 11.8697 12.7499 11.9956V13.1951C12.7506 13.6722 12.9878 14.1295 13.4096 14.4669C13.8315 14.8042 14.4034 14.9939 14.9999 14.9945H18.7499C18.9488 14.9945 19.1396 15.0577 19.2802 15.1701C19.4209 15.2826 19.4999 15.4352 19.4999 15.5942V17.9934Z"
                    fill={color}/>
                <path d="M17.25 7.19739H14.25V8.39694H17.25V7.19739Z" fill={color}/>
                <path
                    d="M6.99937 15.1245C6.44899 14.7306 6.00869 14.2484 5.70725 13.7092C5.40581 13.17 5.25 12.586 5.25 11.9954C5.25 11.4048 5.40581 10.8208 5.70725 10.2816C6.00869 9.74243 6.44899 9.26017 6.99937 8.86633L7.99987 9.76C7.60659 10.0413 7.29197 10.3858 7.07656 10.771C6.86115 11.1561 6.74981 11.5733 6.74981 11.9953C6.74981 12.4172 6.86115 12.8344 7.07656 13.2196C7.29197 13.6048 7.60659 13.9493 7.99987 14.2306L6.99937 15.1245Z"
                    fill={color}/>
                <path
                    d="M4.79959 17.2741C3.77515 16.6594 2.94369 15.8625 2.37103 14.9464C1.79837 14.0302 1.50024 13.0201 1.50024 11.9959C1.50024 10.9717 1.79837 9.96152 2.37103 9.04539C2.94369 8.12927 3.77515 7.33233 4.79959 6.71765L5.70004 7.67718C4.86164 8.18003 4.18116 8.83209 3.71248 9.5817C3.2438 10.3313 2.99979 11.1579 2.99979 11.996C2.99979 12.8341 3.2438 13.6607 3.71248 14.4103C4.18116 15.1599 4.86164 15.812 5.70004 16.3148L4.79959 17.2741Z"
                    fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_4150_5616">
                    <rect width="24" height="19.1929" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
}

export default Mouth;