import { useToast } from '@chakra-ui/react';

const useToastError = () => {
    const toast = useToast();

    const showToast = (message: string) => {
        toast({
            title: "Error",
            description: message,
            status: "error",
            duration: 5000,
            isClosable: true,
            containerStyle: {
                color: "#ffffff",
            },
        });
    };

    return showToast;
};

export default useToastError;
