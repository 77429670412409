import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;
}

const AInteraction :React.FC<Props> = ({color='#fff',height=57,width=57}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4399_4354)">
                <path
                    d="M6.875 34.375V37.8125H14.7572L3.4375 49.1322L5.86781 51.5625L17.1875 40.2428V48.125H20.625V34.375H6.875Z"
                    fill={color}/>
                <path
                    d="M32.6563 24.0625C33.6761 24.0625 34.673 23.7601 35.5209 23.1935C36.3688 22.6269 37.0297 21.8216 37.42 20.8795C37.8103 19.9373 37.9124 18.9005 37.7134 17.9003C37.5145 16.9001 37.0234 15.9813 36.3023 15.2602C35.5812 14.5391 34.6624 14.048 33.6622 13.8491C32.662 13.6501 31.6252 13.7522 30.683 14.1425C29.7409 14.5328 28.9356 15.1937 28.369 16.0416C27.8024 16.8895 27.5 17.8864 27.5 18.9063C27.5 20.2738 28.0432 21.5853 29.0102 22.5523C29.9772 23.5193 31.2887 24.0625 32.6563 24.0625ZM32.6563 17.1875C32.9962 17.1875 33.3285 17.2883 33.6111 17.4772C33.8938 17.666 34.1141 17.9345 34.2442 18.2485C34.3743 18.5626 34.4083 18.9082 34.342 19.2416C34.2757 19.575 34.112 19.8812 33.8716 20.1216C33.6312 20.362 33.325 20.5257 32.9916 20.592C32.6582 20.6583 32.3126 20.6243 31.9985 20.4942C31.6845 20.3641 31.416 20.1438 31.2272 19.8611C31.0383 19.5785 30.9375 19.2462 30.9375 18.9063C30.9375 18.4504 31.1186 18.0132 31.4409 17.6909C31.7632 17.3686 32.2004 17.1875 32.6563 17.1875Z"
                    fill={color}/>
                <path
                    d="M44.6875 6.875H10.3125C9.40082 6.875 8.52648 7.23716 7.88182 7.88182C7.23716 8.52648 6.875 9.40082 6.875 10.3125V27.5H10.3125V10.3125H44.6875V36.3859L38.5172 30.2156C37.8731 29.5754 37.0019 29.216 36.0938 29.216C35.1856 29.216 34.3144 29.5754 33.6703 30.2156L30.9375 32.9484L20.3342 22.3438L17.902 24.7766L24.0625 30.9375L28.5141 35.3891C29.1581 36.0293 30.0294 36.3887 30.9375 36.3887C31.8456 36.3887 32.7169 36.0293 33.3609 35.3891L36.0938 32.6562L44.6875 41.25V44.6875H27.5V48.125H44.6875C45.5992 48.125 46.4735 47.7628 47.1182 47.1182C47.7628 46.4735 48.125 45.5992 48.125 44.6875V10.3125C48.125 9.40082 47.7628 8.52648 47.1182 7.88182C46.4735 7.23716 45.5992 6.875 44.6875 6.875Z"
                    fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_4399_4354">
                    <rect width={width} height={height} fill={color}/>
                </clipPath>
            </defs>
        </svg>

    );
}

export default AInteraction;