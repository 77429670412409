interface VoiceCloningResponse {
    status: string;
    audio: string;
}

export async function voiceCloningApi(
    inputObject: { model:  string; audio: string; text: string },
    token: string
): Promise<{ name: string; response: VoiceCloningResponse }> {
    const { model, audio, text } = inputObject;

    const requestBody = {
        model,
        audio,
        text,
    };
    const response = await fetch(
        `${import.meta.env.VITE_API_ENDPOINT}/api/experiments/voice-cloning`,
        {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json;charset=UTF-8",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(requestBody),
        }
    );

    if (!response.ok) {
        throw new Error(`Call error ${model}`);
    }

    const resData: VoiceCloningResponse = await response.json();

    return { name: model, response: resData };
   
}
