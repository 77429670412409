import React, {useEffect, useMemo, useState} from "react";
import { VStack, Box, Text, Collapse, Button } from "@chakra-ui/react";
import { Catalog, Brain, Ear, Eye, Mouth } from "../../../icons";
import {Link} from "react-router-dom";
import './SidebarMenu.css';
import { useTranslation } from "react-i18next";


// Define the typé for a menu option
interface MenuOption {
    id: number;
    title: string;
    icon: JSX.Element; // If you have an image or icon associated with the option
    link?:string;
    options: SubOption[]; // Suboptions array
}

// Define the typé for a suboption
interface SubOption {
    id: number;
    title: string;
    link: string;
}

interface SidebarMenuProps {
    isMenuOpen: boolean;
    toggleMenu: () => void;
    currentPath: string;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({ isMenuOpen,toggleMenu, currentPath }) => {
    const { t } = useTranslation();
    // Create state to control collapse, stores the id of the element that is open.
    const [openCollapse, setOpenCollapse] = useState<number | null>(null);
    //Status is created to manage the status of sub-options
    const [subOptionActive, setSubOptionActive] = useState<Record<number, number | null>>({}); // Store active suboptions per option

    //function that toggles the collapse status if it opens or closes
    const toggleCollapse = (id: number)=>{
        //if the collapse is open, it closes and passes null, otherwise the element id
        setOpenCollapse(prev => (prev === id ? null : id));
    };
    // Función para manejar clics en opciones
    const handleOptionClick = (id: number) => {
        if (!isMenuOpen) {
            // Si el menú está cerrado, cambia el estado
            toggleMenu();
        }
        // Si el menú está abierto, despliega las opciones del collapse
        toggleCollapse(id);
    };
    // Function to manage clicks on options
    const handleSubOptionClick = (optionId: number, subOptionId:number) => {
        // Update the state with the new active suboption for the specific option
        setSubOptionActive({[optionId]: subOptionId});
    }

    // Create options array with sub-options
    // Use useMemo to avoid recalculating menuOptions on each render
    const menuOptions: MenuOption[] = useMemo(() => [
        {
            id: 1,
            title: t('Home'),
            icon: <Catalog/>,
            link: '/home',
            options: [],
        },
        {
            id: 2,
            title: t('Brain'),
            icon: <Brain />,
            options: [
                { id: 1, title: t('Generative AI'), link: '/play/brain/generative-ai' },
                { id: 2, title: t('Sentiment Analysis'), link: '/play/brain/sentiment-analysis' },
            ],
        },
        {
            id: 3,
            title: t('Ear'),
            icon: <Ear/>,
            options: [
                { id: 1, title: t('Voice Understanding'), link: '/play/ear/voice-understanding' },
            ],
        },
        {
            id: 4,
            title: t('Eyes'),
            icon: <Eye/>,
            options: [
                { id: 1, title: t('Image interaction'), link: '/play/eyes/image-interaction' },
                { id: 2, title: t('Image generation'), link: '/play/eyes/image-generation' },
            ],
        },
        {
            id: 5,
            title: t('Mouth'),
            icon: <Mouth/>,
            options: [
                { id: 1, title: t('AI Voices'), link: '/play/mouth/ai-voices' },
                { id: 2, title: t('Voice cloning'), link: '/play/mouth/voice-cloning' },
            ],
        },
    ], [t]);

    useEffect(() => {
        // Iterate over all menu options to mark them as active based on the currentPath
        menuOptions.forEach(option => {
            // Check if the currentPath matches the link of the option
            if (option.link && currentPath === option.link) {
                setOpenCollapse(option.id); // Ensure the collapse for the option is open
                setSubOptionActive({}); // Clear active suboption if the main option is directly clicked
            }

            // Check if any sub-option matches the current URL
            const activeSubOption = option.options.find(subOption => currentPath === subOption.link);
            if (activeSubOption) {
                setOpenCollapse(option.id); // Ensure the collapse for the option is open
                setSubOptionActive({ [option.id]: activeSubOption.id }); // Set the active sub-option
            }
        });
    }, [currentPath, menuOptions]); // Use currentPath to trigger the effect

    return (
        <VStack align="start" spacing={4} p={4} pt="110px" className={!isMenuOpen  ?"wrapper-side-menu close" : "wrapper-side-menu"}>
            {menuOptions.map((option)=>(
                <Box
                    key={option.id}
                    className={`side-menu-option ${openCollapse === option.id ? 'active': ''}`}
                >
                    {option.link ? (
                        <Link to={option.link} key={option.id}>
                            <Button
                                className='option-content'
                                leftIcon={option.icon}
                                onClick={()=>{
                                    handleOptionClick(option.id);
                                }}
                            >
                                <Text>
                                    {option.title}
                                </Text>
                            </Button>
                        </Link>
                    ) : (
                        <Button
                            leftIcon={option.icon}
                            className='option-content'
                            onClick={()=>{
                                handleOptionClick(option.id);
                            }}
                        >
                            <Text>
                                {option.title}
                            </Text>
                        </Button>
                    )}

                    <Collapse in={openCollapse === option.id}>
                        <VStack>
                            {option.options.map((subOption)=>(
                                <Link
                                    className={`suboption-content ${subOptionActive[option.id] === subOption.id ? 'active' : ''}`}
                                    to={subOption.link}
                                    key={subOption.id}
                                    onClick={() => handleSubOptionClick(option.id, subOption.id)}
                                >
                                    <Text>
                                        {subOption.title}
                                    </Text>
                                </Link>
                            ))}
                        </VStack>
                    </Collapse>
                </Box>
            ))}
        </VStack>
    );
};

export default SidebarMenu;
