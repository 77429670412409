import React from 'react';

interface Props {
    color?: string;
    height?: number;
    width?: number;}

const VoiceUnderstandingIcon: React.FC<Props> = ({color='#fff',height=57,width=57}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 40.375H23.75" stroke={color} stroke-width="4.5" stroke-linecap="round"/>
            <path d="M19 9.5V19M11.875 4.75V23.75M4.75 11.875V16.625M26.125 11.875V16.625" stroke={color} stroke-width="3.5" stroke-linecap="round"/>
            <path
                d="M9.50012 30.875C9.50406 40.7353 9.61293 45.7779 12.6303 48.9545C15.7606 52.25 20.7987 52.25 30.875 52.25H31.9437C40.9849 52.25 45.5052 52.25 48.5034 49.5541C48.9293 49.1715 49.3256 48.754 49.6893 48.3058C52.25 45.1495 52.25 40.3904 52.25 30.8724C52.25 21.3542 52.25 16.5952 49.6893 13.4388C49.3256 12.9906 48.9293 12.5732 48.5034 12.1904C45.8828 9.83407 42.099 9.53736 35.15 9.5H33.25"
                stroke={color} stroke-width="3.5" stroke-linecap="round"/>
        </svg>
    );
};
export default VoiceUnderstandingIcon;