import React from 'react';
import {Button} from "@chakra-ui/react";
import {DownloadIcon} from "@chakra-ui/icons";
import './DownloadButton.css';

interface Props {
    audioUrl: string;
    fileName: string;
}

const DownloadButton: React.FC<Props> = ({ audioUrl, fileName}) => {
    return (
        <div className="wrapper-download-button">

            <a href={audioUrl} download={fileName}>
                <Button
                    leftIcon={<DownloadIcon />}
                    color="gray"
                    variant={"solid"}
                >Download
                </Button>
            </a>
        </div>
    );
};

export default DownloadButton;