export interface FormValues {
    model: string;
    system_prompt: string;
}

export const imageProviders = [
    { name: "OpenAI - DALL-E 3", value: "openai:dall-e-3" },
    { name: "FLUX.1 [schnell]", value: "replicate:flux-schnell" },
    { name: "FLUX.1.1 [pro]", value: "replicate:flux-1.1-pro" },
    { name: "FLUX.1 [dev]", value: "replicate:flux-dev" }
];