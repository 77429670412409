import { useTranslation } from "react-i18next";
import { Box, Text, Link } from "@chakra-ui/react";
import { SubscriptionBannerProps } from "./interfaces";
import { useCheckAuth } from "../../../hooks/useCheckAuth";

export const SubscriptionBanner: React.FC<SubscriptionBannerProps> = ({
    onRenew,
}) => {
    const { t } = useTranslation();
    const { stateScope } = useCheckAuth();

    const message = stateScope.some((scope) => scope.includes("billing"))
        ? t("NO ACTIVE SUBSCRIPTIONS")
        : t("No active subscriptions. Please contact your administrator for more information.");

    return (
        <Box
            bg="#CE0015"
            color="white"
            p={4}
            textAlign="center"
            boxShadow="lg"
        >
            <Text fontSize="lg" fontWeight="bold">
                {message}
                <Link
                    onClick={onRenew}
                    color="white"
                    textDecoration="underline"
                    cursor="pointer"
                    ml={2}
                >
                    {t("Renew your payment to use your products.")}
                </Link>
            </Text>
        </Box>
    );
};
