import {Box, Flex, Stack, Text, useToast} from "@chakra-ui/react";
import MainTitle from "../../../../ui/Components/MainTitle";
import Subtitle from "../../../../ui/Components/SubtitleSeccion";
import {useDropzone} from "react-dropzone";
import {useCallback, useState} from "react";
import Cloud from "../../../../icons/cloud.tsx";
import ActionButton from "../../../../ui/Components/Button";
import {ImageInteractionChat} from "./ImageInteractionChat";
import {useNavigate} from "react-router-dom";
import ImageInteractionIcon from "../../../../icons/eyes/imageinteraction.tsx";
import { useTranslation } from "react-i18next";

export const ImageInteraction = () => {
    const [image, setImage] = useState<string | null>(null);
    const { t } = useTranslation();
    const [ratio, setRatio] = useState<number | null>(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();
    // 200MB en bytes
    const maxSize = 200 * 1024 * 1024;
    // 1MB en bytes
    /*const maxSize = 1 * 1024 * 1024;*/

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file) {

            if (file.size > maxSize) {
                toast({
                    title: "Error",
                    description: t("The file exceeds the maximum size of 200MB."),
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                const imageRawData = reader.result as string;
                const img = new Image();
                setImage(imageRawData);
                img.src = imageRawData;
                img.onload = () => {
                    const calculatedRatio = img.width / img.height;
                    setRatio(calculatedRatio);
                };
            };

            reader.readAsDataURL(file);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxSize, toast]);

    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: {
            'image/jpg': [],
            'image/jpeg': [],
            'image/png': [],
        }
    });

    const handleReset = () => {
        setImage(null);
        setImageLoaded(false)
    };

    const onHandleChat = () => {
        setImageLoaded(true);
    };

    const backNavigate = () => {
        navigate('/')
    };

    return (
        <Box
            maxWidth="670px"
            minHeight="100vh"
            w="670px"
            mx="auto"
            py="100px"
            px="15px"
            borderRadius="md"
            position="relative"
            color="white"
        >
            <Text
                fontFamily="Lato-regular"
                fontSize="18px"
                as="a"
                color="white.50"
                onClick={backNavigate}
                className="back-button"
                style={
                    {
                        right: "inherit",
                        left: "0",
                        top:"50px",
                        position: "absolute",
                    }
                }
            >
                {t("< back")}
            </Text>
            <Stack alignItems="flex-start" spacing="46.5px">
                <Stack direction="row" spacing="52px" alignItems="center">
                    <Flex mb="46.5px" alignItems="center">
                        <Flex mr="52px">
                            <MainTitle text={t("Eyes")}/>
                        </Flex>
                        <Flex alignItems="center">
                            <Subtitle text={t("Image interaction")} icon={<ImageInteractionIcon width={24} height={24} color="#FFF" />} />
                        </Flex>
                    </Flex>
                </Stack>
                <Text fontWeight="bold" fontSize="20px">
                    {t("Upload an image and ask whatever you want to the AI.")}
                </Text>
                {!imageLoaded &&
                    <>
                        {!image &&
                            <Box
                                {...getRootProps()}
                                border="2px dashed #ccc"
                                borderRadius="md"
                                p="20px"
                                display="flex"
                                height={"220px"}
                                justifyContent="center"
                                alignItems="center"
                                flexDir={"column"}
                                cursor="pointer"
                                mb="20px"
                                width={"100%"}
                            >
                                <input {...getInputProps()} />
                                <Cloud/>
                                <Text fontSize={"16px"} mt="20px" mb="20px" color="white">
                                    {t("Drag and drop here (limit 200MB per file - JPG, JPEG, PNG)")}
                                </Text>
                                <Text fontSize={"16px"} mb="20px" color="white">
                                    {t('or')}
                                </Text>
                                <Text fontSize={"18px"} mb="20px" color="blue.50">
                                    {t("Browse files")}
                                </Text>
                            </Box>
                        }

                        {image && ratio !== null && (
                            <>
                                <Box
                                    width="100%"
                                    maxWidth="100%"
                                    height="auto"
                                    position="relative"
                                    mt="20px"
                                    margin={"0 auto"}
                                    overflow="hidden"
                                    borderRadius="8px"
                                >
                                    <img
                                        src={image}
                                        alt="Imagen cargada"
                                        style={{
                                            width: "100%",
                                            height: "auto",
                                            objectFit: "contain"
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <ActionButton text={t("Ask IA")} onClick={onHandleChat}/>
                                </Box>
                            </>
                        )}
                    </>
                }
                {imageLoaded &&
                    <ImageInteractionChat image={image} onReset={handleReset}></ImageInteractionChat>
                }
            </Stack>
        </Box>
    );
}
