import React, { useState, useRef, useEffect, useCallback } from "react";
import { Flex, Text, IconButton, useTheme, Button } from "@chakra-ui/react";
import { FiPlay, FiPause, FiDownload } from "react-icons/fi";
import base64ToBlob from '../../../../utils/base64ToBlob';
import { useTranslation } from "react-i18next";

interface ResponseAudioVoiceProps {
  base64Audio: string;
  fileName?: string;
}

const ResponseAudioVoice: React.FC<ResponseAudioVoiceProps> = ({
    base64Audio,
    fileName = "audio.wav",
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const white = theme.colors.white[50];
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioUrl, setAudioUrl] = useState<string | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        const blob = base64ToBlob(base64Audio, "audio/wav");
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);

        return () => {
            URL.revokeObjectURL(url);
        };
    }, [base64Audio]);

    const handlePlayPause = useCallback(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
                setIsPlaying(false);
            } else {
                audioRef.current.play().catch((error) => {
                    console.error("Failed to play audio:", error);
                });
                setIsPlaying(true);
            }
        }
    }, [isPlaying]);

    useEffect(() => {
        const audioElement = audioRef.current;
        const handleAudioEnded = () => {
            setIsPlaying(false);
        };
        if (audioElement) {
            audioElement.addEventListener("ended", handleAudioEnded);
        }
        return () => {
            if (audioElement) {
                audioElement.removeEventListener("ended", handleAudioEnded);
            }
        };
    }, []);

    const handleDownload = () => {
        if (audioUrl) {
            const link = document.createElement("a");
            link.href = audioUrl;
            link.download = fileName;
            link.click();
        }
    };

    return (
        <Flex
            p={4}
            justifyContent="center"
            flexDir="column"
            alignItems="center"
            gap={2}
            width="100%"
        >
            <Flex
                bg="green.100"
                p={4}
                borderRadius="full"
                justifyContent="center"
                alignItems="center"
                gap={2}
                boxShadow="md"
                width="100%"
                cursor="pointer"
                height="50px"
                onClick={handlePlayPause}
            >
                <audio ref={audioRef} src={audioUrl || undefined} />

                <IconButton
                    icon={isPlaying ? <FiPause size={22} color={white} /> : <FiPlay size={22} color={white} />}
                    aria-label={isPlaying ? t("Pause Audio") : t("Play Audio")}
                    colorScheme="whiteAlpha"
                    variant="ghost"
                    onClick={handlePlayPause}
                    sx={{
                        _hover: {
                            bg: "none",
                        },
                        _active: {
                            bg: "none",
                        },
                    }}
                />

                <Text color={white} fontWeight="bold" fontSize="md">
                    {isPlaying ? t("Playing...") : t("Your VOICE AI")}
                </Text>

        
            </Flex>

            <Button
                leftIcon={<FiDownload />}
                colorScheme="white.50"
                variant="ghost"
                onClick={handleDownload}
                _hover={{ bg: "none" }}
                _active={{ bg: "none" }}
            >
                {t("Download")}
            </Button>
        </Flex>
    );
};

export default ResponseAudioVoice;
