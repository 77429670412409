import React, {ReactNode} from 'react';
import './CustomWrapper.css';
import {Box} from "@chakra-ui/react";

interface Props {
    children: ReactNode;
}

const CustomWrapper: React.FC<Props> = ({ children }) => {
    return (
        <Box className="custom-wrapper">
            <div className="inner-custom-wrapper">
                {children}
            </div>
        </Box>
    )
}

export default CustomWrapper;