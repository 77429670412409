import { Textarea, Flex , Text, Box} from "@chakra-ui/react";
import React, { useState } from "react";

interface TextAreaInputProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  label?: string;
  height?: string;
  disabled?: boolean;
}

const TextAreaInput: React.FC<TextAreaInputProps> = ({ placeholder, value, onChange, label, height = "174px", disabled = false }) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <Flex flexDir="column" alignItems="flex-start" width="100%" mb="15px" >
            {label && (
                <Text mb="7px" fontSize="16px" color="white.50" fontWeight="normal">
                    {label}
                </Text>
            )}
            <Box position="relative" width="100%">
                {!value && !isFocused && (
                    <Text
                        position="absolute"
                        bottom="16px"
                        left="16px"
                        fontSize="sm"
                        color="whiteAlpha.700"
                        pointerEvents="none"
                        zIndex="1"
                    >
                        {placeholder}
                    </Text>
                )}
      
                <Textarea
                    height={height}
                    bg="grey.600"
                    placeholder=""
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    resize="none"
                    disabled={disabled}
                    _focus={{ borderColor: "blue.500" }}
                />
            </Box>
        </Flex>
    );
};

export default TextAreaInput;
